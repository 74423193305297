import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { LabeledSwitch } from 'components/common/compositions/LabeledSwitch';
import { CopyText } from 'components/common/CopyText';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from 'components/common/StyledAccordion';
import { Text } from 'components/styles';
import { AnchorPoints, ProcessStatusLabel } from 'utils/constants';
import { RunInfoFileStatus, RunInfoProcessStatus } from 'utils/types';

import { InfoViewerTable, InfoViewerTableProps } from '../infoViewer/InfoViewerTable';
import InfoStatusIcon from './InfoStatusIcon';
import { InfoViewerTextPopover } from './InfoViewerTextPopover';
import { ProcessStatusRowContents } from './ProcessStatusRowContents';
import { InfoViewerBodyProps } from './types';

export const useStyles = makeStyles(() => ({
  root: {},
}));

const ProcessHeaders: InfoViewerTableProps['headers'] = [
  null,
  'Status',
  'Processing ID',
  'Run Directory',
  'Ran On',
  'Ran By',
  'Logs',
];
const OutputsHeaders: InfoViewerTableProps['headers'] = [
  null,
  'Files or Datastream',
  'Validation Errors',
  'Archive Status',
  'Release By',
  'Release On',
  'Comment',
];

const InfoViewerBody = observer((props: InfoViewerBodyProps): React.ReactElement | null => {
  const { className, pipelineId, dataDate } = props;
  const [showLatestProcessOnly, setLatestProcessOnly] = useState(true);

  const classes = useStyles(props);
  const { processingService } = useServices();
  const { infoViewerData } = processingService;
  const { processType } = pipelineId;

  const info = infoViewerData && infoViewerData[processType];
  if (!info) return null;

  const { process_status: process_statuses, file_status } = info;

  const latestProcessStatus: Partial<RunInfoProcessStatus> = useMemo(
    () => process_statuses.at(0) ?? {},
    [process_statuses]
  );
  const status = latestProcessStatus.status ?? ProcessStatusLabel.SUCCESS;

  // Determine whether collapsible should start expanded based on the status
  const autoExpand =
    status !== ProcessStatusLabel.SUCCESS || file_status.some((fs) => Object.keys(fs.validation_errors).length > 0);
  const showLatestOnlyToggle = process_statuses.length > 1;

  const tableRows = useMemo(() => {
    const statusList = showLatestProcessOnly ? [latestProcessStatus] : process_statuses;
    return statusList.map((processStatus) => ProcessStatusRowContents({ processStatus, pipelineId, dataDate }));
  }, [dataDate, latestProcessStatus, pipelineId, process_statuses, showLatestProcessOnly]);

  return (
    <div className={clsx(classes.root, className)}>
      <StyledAccordion defaultExpanded={autoExpand}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text>{processType}</Text>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {/* Process Table */}
          <InfoViewerTable
            tableTitle={
              <Stack direction="row" justifyContent="space-between">
                <span>Process</span>
                {showLatestOnlyToggle && (
                  <LabeledSwitch
                    checked={showLatestProcessOnly}
                    onClick={() => setLatestProcessOnly(!showLatestProcessOnly)}
                    label={`Latest status only (${process_statuses.length})`}
                    placement="start"
                  />
                )}
              </Stack>
            }
            headers={ProcessHeaders}
            rows={tableRows}
          />

          {/* Outputs Table */}
          <InfoViewerTable
            tableTitle="Outputs"
            headers={OutputsHeaders}
            rows={file_status.map((status) => [
              Object.keys(status.validation_errors).length ? (
                <InfoStatusIcon key={'statusIcon'} status={ProcessStatusLabel.ERROR} />
              ) : (
                <InfoStatusIcon key={'statusIcon'} status={ProcessStatusLabel.SUCCESS} />
              ),
              <InfoViewerTextPopover
                key={'filePath'}
                trigger="hover"
                popoverId={'infoViewerFilePathPopover'}
                popoverText={status.path}
                popoverPosition={AnchorPoints.BottomLeft}
                text={
                  <CopyText
                    tooltipText="Copy full path"
                    text={status.name}
                    copiedText={status.path}
                    tooltipProps={{ placement: 'top', arrow: false }}
                  />
                }
              />,
              <ValidationErrorStack key="validationErrors" validationErrors={status.validation_errors} />,
              status.archive_status,
              status.released_by,
              status.released_on,
              status.release_comment ? (
                <InfoViewerTextPopover
                  trigger="click"
                  key={'commentHover'}
                  text={
                    <IconButton key={'comment'}>
                      <CommentIcon />
                    </IconButton>
                  }
                  popoverId={'infoViewerCommentPopover'}
                  popoverText={status.release_comment}
                />
              ) : (
                '- - -'
              ),
            ])}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
});

export default InfoViewerBody;

interface ValidationErrorStackProps {
  validationErrors: RunInfoFileStatus['validation_errors'];
}

const ValidationErrorStack = ({ validationErrors, ...props }: ValidationErrorStackProps) => {
  return (
    <Stack>
      {Object.entries(validationErrors).map(([key, value]) => {
        // return key;
        return (
          <InfoViewerTextPopover
            trigger="hover"
            key={'validationErrorHover'}
            text={key}
            popoverId={'infoViewerValidationErrorPopover'}
            popoverText={value}
          />
        );
      })}
    </Stack>
  );
};
