import React, { useState } from 'react';

import { useServices } from 'services';

import { PipelineIdentifier } from 'utils/types';

type UseContextMenuProps = {
  onContextClose?: () => void;
};

export const useContextActionMenu = ({ onContextClose }: UseContextMenuProps = {}) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { processingService } = useServices();

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>, pipelineId?: PipelineIdentifier | null) => {
    if (!actionMenuOpen) {
      // Open custom menu if context menu isn't already open and mouse position has changed
      event.preventDefault();
      closeContextMenu();

      openContextMenu(event);

      // Set current row as the selected pipeline whenever we open the context menu
      processingService.setSelectedPipelineId(pipelineId ?? null);
    } else {
      processingService.clearSelectedPipelineId();
    }
  };

  const openContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setActionMenuOpen(true);
  };

  const closeContextMenu = () => {
    setActionMenuOpen(false);
    setAnchorEl(null);

    if (onContextClose) onContextClose();
  };

  return {
    anchorEl,
    actionMenuOpen,
    handleContextMenu,
    openContextMenu,
    closeContextMenu,
  };
};
