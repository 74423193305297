/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { ActionLink } from 'components/common/ActionLink';
import { ResolveErrorPopover } from 'components/common/ResolveErrorPopover';
import { UseStyles } from 'styles/utilityTypes';
import { FileDownloadTypes, LogTypeLabel, PipelineErrorState } from 'utils/constants';
import { PipelineIdentifier, PipelineLogError } from 'utils/types';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.3rem',
  },
}));

export interface ActionsCellProps extends UseStyles<typeof useStyles> {
  pipelineId: PipelineIdentifier;
  sys_id: PipelineLogError['id'];
  inc_url: PipelineLogError['inc_url'];
  logfileKeys: PipelineLogError['logfile_keys'];
  processingId: PipelineLogError['processing_id'];
  procType: PipelineLogError['proc_type'];
  state: PipelineLogError['state'];

  disabled?: boolean;
  showErrorHistory?: boolean;
  resolveErrorHandler?: (comment: string) => void;
  className?: string;
}

const ActionsCell = observer((props: ActionsCellProps): React.ReactElement | null => {
  const {
    className,
    inc_url,
    logfileKeys,
    sys_id,
    procType: processType,
    state,
    processingId,
    pipelineId,
    showErrorHistory = true,
    disabled,
    resolveErrorHandler,
  } = props;
  const [comment, setComment] = useState<string>(''); // Controlling comment text retains input in case user accidentally close popover.

  const classes = useStyles(props);
  const { processingService } = useServices();

  const showResolveError = state !== PipelineErrorState.RESOLVED;

  // TODO: move handlers executed in `ActionLink` into a hook
  const handleViewErrorHistory = () => {
    processingService.fetchPipelineError({
      pipelineId: { ...pipelineId, processType, processingId },
      defaultTab: processType,
      getHistory: true,
    });
  };

  // TODO: move handlers executed in `ActionLink` into a hook
  const handleViewFile = (fileType: FileDownloadTypes) => {
    processingService.fetchLogFile(pipelineId, { id: sys_id }, fileType);
  };

  // Surrounding links with <span/> elements to avoid clickable area spanning width of cell
  return (
    <div className={clsx(classes.root, className)}>
      {inc_url && (
        <span>
          <ActionLink disabled={disabled} href={inc_url} target="_blank">
            View INC
          </ActionLink>
        </span>
      )}
      {logfileKeys.map((fileType) => (
        <span key={fileType}>
          <ActionLink disabled={disabled} onClick={() => handleViewFile(fileType)}>
            View {LogTypeLabel[fileType]}
          </ActionLink>
        </span>
      ))}
      {showErrorHistory && (
        <span>
          <ActionLink disabled={disabled} onClick={handleViewErrorHistory}>
            View Error History
          </ActionLink>
        </span>
      )}
      {showResolveError && (
        <span>
          <ResolveErrorPopover
            actionLinkProps={{ disabled }}
            commentProps={{ commentText: comment, setCommentText: setComment }}
            onResolveError={resolveErrorHandler}
          />
        </span>
      )}

      {/* TODO: need to implement */}
      {/* <ActionLink>Lock Process</ActionLink> */}
    </div>
  );
});

export default ActionsCell;
