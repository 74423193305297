import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { TableRow, TableRowProps } from '@mui/material';

import { ErrorHistory } from 'utils/types';

import { StyledTableCell } from './StyledTableCell';

export interface ErrorHistoryTableRowProps extends TableRowProps {
  errorInfo: ErrorHistory;
}

/** @deprecated No longer used - All errors objects formatted like `PipelineLogError` */
const ErrorHistoryTableRow = observer(({ errorInfo, ...props }: ErrorHistoryTableRowProps) => {
  const { error_code, error_level, error_message, occurrence, process_logfiles } = errorInfo;
  const rowDataList: ReactNode[] = [error_message, error_level, error_code, occurrence, process_logfiles];
  return (
    <TableRow {...props}>
      {rowDataList.map((content, idx) => (
        <StyledTableCell key={idx}>{content}</StyledTableCell>
      ))}
    </TableRow>
  );
});

export default ErrorHistoryTableRow;
