import { action, computed, makeObservable, observable } from 'mobx';

import { DateTime } from 'luxon';

import { ProcessType } from 'utils/constants';
import { DateRange } from 'utils/types';
import { EncodeBase64 } from 'utils/utils';

import { ArmflowForm } from './types';

export type RunProcessFormJSON = {
  process_name: string | null;
  process_type: ProcessType | null;
  location_name: string | null;
  processing_id: string | null;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  // start_date: Date | null;
  // end_date: Date | null;
  is_reprocessing: boolean | null;
  command_line_args: string[] | null;
};

export type RunProcessFormFields = {
  processName: string | null;
  processType: ProcessType | null;
  locationName: string | null;
  processingId: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  isReprocessing: boolean;
  commandLineArgs: string | null;
};

export const RUN_PROCESS_EMPTY_FORM: RunProcessFormFields = {
  processName: null,
  processType: null,
  locationName: null,
  processingId: null,
  startDate: null,
  endDate: null,
  isReprocessing: false,
  commandLineArgs: null,
};

export default class RunProcessForm implements ArmflowForm<RunProcessFormFields, RunProcessFormJSON> {
  @observable processName: RunProcessFormFields['processName'];
  @observable processType: RunProcessFormFields['processType'];
  @observable locationName: RunProcessFormFields['locationName'];
  @observable processingId: RunProcessFormFields['processingId'];
  @observable startDate: RunProcessFormFields['startDate'];
  @observable endDate: RunProcessFormFields['endDate'];
  @observable isReprocessing: RunProcessFormFields['isReprocessing'];
  @observable commandLineArgs: RunProcessFormFields['commandLineArgs'];

  constructor(autofillData?: RunProcessFormFields) {
    this.processName = autofillData ? autofillData.processName : '';
    this.processType = autofillData ? autofillData.processType : null;
    this.locationName = autofillData ? autofillData.locationName : '';
    this.processingId = autofillData ? autofillData.processingId : '';
    this.startDate = autofillData ? autofillData.startDate : null;
    this.endDate = autofillData ? autofillData.endDate : null;
    this.isReprocessing = autofillData ? autofillData.isReprocessing : false;
    this.commandLineArgs = autofillData ? autofillData.commandLineArgs : null;

    makeObservable(this);
  }

  @computed get toHistoryString(): string {
    const { processName, locationName, processingId, startDate, endDate } = this;
    // console.log([processName, locationName, processingId, startDate, endDate].join(' '));
    return [processName ?? '', locationName ?? '', processingId ?? '', startDate ?? '', endDate ?? ''].join(' ');
  }

  @computed get query(): string {
    return EncodeBase64(JSON.stringify(this));
  }

  // Basic form updaters
  @action updateProcessName(name: string | null) {
    this.processName = name;
  }

  @action updateProcessType(processType: ProcessType | null) {
    this.processType = processType;
  }

  @action updateLocation(location: string | null) {
    this.locationName = location;
  }

  @action updateProcessingId(processingId: string) {
    this.processingId = processingId;
  }

  @action updateDate(dateRange: DateRange) {
    this.startDate = dateRange.begin;
    this.endDate = dateRange.end;
  }

  @action toggleReprocessing(is_reprocessing?: boolean) {
    if (is_reprocessing !== undefined) this.isReprocessing = is_reprocessing;
    else this.isReprocessing = !this.isReprocessing;
  }

  // Advanced form updaters
  @action updateCommandLineArgs(commandLineArgs: string) {
    this.commandLineArgs = commandLineArgs;
  }

  @action updateForm(formValues: Partial<RunProcessFormFields>) {
    const {
      processName,
      processType,
      locationName,
      processingId,
      startDate,
      endDate,
      isReprocessing,
      commandLineArgs,
    } = formValues;

    if (processName !== undefined) this.processName = processName;
    if (processType !== undefined) this.processType = processType;
    if (locationName !== undefined) this.locationName = locationName;
    if (processingId !== undefined) this.processingId = processingId;
    if (startDate !== undefined) this.startDate = startDate;
    if (endDate !== undefined) this.endDate = endDate;
    if (isReprocessing !== undefined) this.isReprocessing = isReprocessing;

    if (commandLineArgs !== undefined) this.commandLineArgs = commandLineArgs;
  }

  @action reset() {
    this.processName = '';
    this.processType = null;
    this.locationName = '';
    this.processingId = '';
    this.startDate = null;
    this.endDate = null;
    this.isReprocessing = false;
    this.commandLineArgs = null;
  }

  toJSON(): RunProcessFormJSON {
    let json_to_stringify: RunProcessFormJSON = {
      process_name: null,
      process_type: null,
      location_name: null,
      processing_id: null,
      start_date: null,
      end_date: null,
      is_reprocessing: null,
      command_line_args: null,
    };

    json_to_stringify.process_name = this.processName;
    json_to_stringify.process_type = this.processType;
    json_to_stringify.location_name = this.locationName;
    if (this.isReprocessing) {
      json_to_stringify.processing_id = this.processingId;
    }
    json_to_stringify.start_date = this.startDate?.toISODate();
    json_to_stringify.end_date = this.endDate?.toISODate();
    json_to_stringify.is_reprocessing = this.isReprocessing;
    // TODO: this will split quotations as well. I assume we'll need to allow quotations in the CLI too?
    if (this.commandLineArgs) {
      json_to_stringify.command_line_args = this.commandLineArgs
        ?.trim()
        .split(' ')
        .filter((char) => char !== '');
    }

    return json_to_stringify;
  }

  @action
  fromJSON(formValues: Partial<RunProcessFormJSON>) {
    const convertedFields: RunProcessFormFields = RunProcessForm.fromJSON(formValues);
    this.updateForm(convertedFields);
  }

  /** Created static method so components don't need to construct class instance
   *  @todo Could probably just move this outside of class
   */
  static fromJSON(formValues: Partial<RunProcessFormJSON>): RunProcessFormFields {
    const convertedFields: RunProcessFormFields = {
      processName: formValues.process_name ?? null,
      processType: formValues.process_type ?? null,
      locationName: formValues.location_name ?? null,
      processingId: formValues.processing_id ?? null,
      startDate: formValues.start_date ? DateTime.fromISO(formValues.start_date) : null,
      endDate: formValues.end_date ? DateTime.fromISO(formValues.end_date) : null,
      isReprocessing: formValues.is_reprocessing ?? false,
      commandLineArgs: formValues.command_line_args?.join(' ') ?? null,
    };
    return convertedFields;
  }
}
