import React, { CSSProperties } from 'react';

import { observer } from 'mobx-react';

import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled, Tooltip, TooltipProps } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { Spacer } from 'components/styles';
import { useCopyTextHandler, UseCopyTextHandlerProps } from 'hooks/useCopyTextHandler';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface CopyTextProps extends UseCopyTextHandlerProps {
  className?: string;
  showTooltip?: boolean;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children' | 'open' | 'onClick'>;
}

const CopySpan = styled('span')`
  /* display: flex;
  align-items: center; */

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  :active {
    filter: brightness(80%);
    cursor: pointer;
  }
`;

export const CopyText = observer((props: CopyTextProps): React.ReactElement | null => {
  const { className, text, showTooltip, tooltipProps } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const { handleCopy, showCheckmark, copiedStatusText } = useCopyTextHandler(props);

  const iconStyle: CSSProperties = { fontSize: theme.typography.body2.fontSize };
  const icon = showCheckmark ? <CheckIcon style={iconStyle} /> : <ContentCopyIcon style={iconStyle} />;

  return (
    <Tooltip title={copiedStatusText} open={showTooltip} arrow placement="top" onClick={handleCopy} {...tooltipProps}>
      <CopySpan className={clsx(classes.root, className)}>
        {text} <Spacer /> {icon}
      </CopySpan>
    </Tooltip>
  );
});
