import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DndContext } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import clsx from 'clsx';
import { AutoSizer, GridCellProps, Index, MultiGrid, ScrollIndices } from 'react-virtualized';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { DATE_ROW_HEIGHT, ROW_HEIGHT } from 'utils/constants';

import ProcessRun from '../ProcessRun';
import { NoContentPlaceholder } from './gridCells/NoContentPlaceholder';
import { TimelineScrollSyncCorner } from './gridCells/TimelineScrollSyncCorner';
import { TimelineScrollSyncHeader } from './gridCells/TimelineScrollSyncHeader';
import { TimelineScrollSyncLabel } from './gridCells/TimelineScrollSyncLabel';
import { ResizeBar } from './ResizeBar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(0, 1),
    paddingRight: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },

  timelinesView: {
    maxHeight: `calc(100vh - ${LEGEND_HEIGHT}px)`,
    overflowY: 'auto',
    justifyContent: 'space-between',
  },

  labelRow: {
    height: ROW_HEIGHT,
  },

  timelineRow: {
    height: ROW_HEIGHT,
  },

  timelineBox: {
    position: 'relative',
    height: '100%',
    borderWidth: 2,
    '&:hover': {
      filter: `brightness(${0.8})`,
    },
  },

  timelineCorner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
  },

  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '100%',
  },

  dateHeaderGrid: {
    // this override allows the month to flow out of container
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      overflow: 'visible !important',
    },
  },

  // Styles based on timeline data
  runningTimeline: {
    color: theme.palette.running.main,
  },

  hideScrollbar: {
    // For Firefox
    scrollbarWidth: 'none',

    // For non-Firefox desktop browsers
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const NULL_NUM: number = -1;
const LEGEND_HEIGHT: number = 300;
const LEFT_RIGHT_GRID_CLASSNAME: string = 'AirflowBottomGrids';

export interface TimelinesFuncProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const Timelines = observer((props: TimelinesFuncProps): React.ReactElement | null => {
  const { className } = props;
  const classes = useStyles(props);
  // const theme = useTheme();
  const { actionBarService, processingService } = useServices();

  const timelineGrid: React.RefObject<MultiGrid> = React.createRef();

  const { intervalCellWidth: boxWidth, labelWidth } = actionBarService;

  // Grid size-related state
  const [previousZoom, setPreviousZoom] = useState(actionBarService.zoomFactor);
  const [previousLabelWidth, setPreviousLabelWidth] = useState(actionBarService.labelWidth);

  // Hover-related state
  const [hoveredRowIndex, setHoveredRowIndex] = useState(NULL_NUM);
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState(NULL_NUM);
  const [selectedRowIndex, setSelectedRowIndex] = useState(NULL_NUM);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(NULL_NUM);
  // const [contextMouseX, setContextMouseX] = useState(NULL_NUM);
  // const [contextMouseY, setContextMouseY] = useState(NULL_NUM);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  // const { setNodeRef } = useDroppable({ id: 'timeline-grid-container' });

  const timelineData = processingService.timelineData;

  // Recompute grid cell sizes based on various conditions
  useEffect(() => {
    let recomputeGrid = false;
    if (actionBarService.zoomFactor !== previousZoom) {
      setPreviousZoom(actionBarService.zoomFactor);
      if (!recomputeGrid) recomputeGrid = true;
    }
    if (actionBarService.labelWidth !== previousLabelWidth) {
      setPreviousLabelWidth(actionBarService.labelWidth);
      if (!recomputeGrid) recomputeGrid = true;
    }

    // Refresh grid sizes if flag's raised
    if (recomputeGrid) timelineGrid.current?.recomputeGridSize();
  }, [previousZoom, actionBarService.zoomFactor, timelineGrid, actionBarService.labelWidth, previousLabelWidth]);

  const handleAltDown = useCallback(
    (event: KeyboardEvent) => {
      // Block scrolling on grid elements while Alt+Shift are pressed
      if (event.altKey && event.shiftKey && !event.repeat) {
        let elements = document.getElementsByClassName(LEFT_RIGHT_GRID_CLASSNAME);
        for (let i = 0; i < elements.length; i++) {
          // @ts-ignore
          elements[i].style.overflow = 'hidden';
          actionBarService.zoomModeToggle(true);
        }
      }
    },
    [actionBarService]
  );

  const handleAltUp = useCallback(
    (event: KeyboardEvent) => {
      // Resume scrolling on release of Alt or Shift
      if (event.key === 'Alt' || event.key === 'Shift') {
        let elements = document.getElementsByClassName(LEFT_RIGHT_GRID_CLASSNAME);
        for (let i = 0; i < elements.length; i++) {
          // @ts-ignore
          elements[i].style.overflow = 'auto';
          actionBarService.zoomModeToggle(false);
        }
      }
    },
    [actionBarService]
  );

  // TODO: Need to finish implementing month-by-month navigation (#63)
  // const handleRenderedSection = useCallback(
  //   (params: RenderedSection) => {
  //     // const updateRenderedIndices = debounce(() => {
  //     // Only update the rendered date stored. Updating column stored
  //     // const { currentRenderedStartColumn, skipToStartColumn } = processingService;
  //     const { columnStartIndex, columnStopIndex } = params;

  //     const startDate = new Date(processingService.timelineData.start_date);
  //     const startDateOffset = columnStartIndex - 1;
  //     // const endDateIndex = columnStopIndex - 1;

  //     const headerDate = getDateFromOffset(startDate, startDateOffset);
  //     actionBarService.setCurrentRenderedStartDate(headerDate);
  //     // processingService.setCurrentRenderedEndDate(getDateFromOffset(startDate, startDateIndex));

  //     const multigridColumnStart = columnStartIndex + 1;
  //     const multigridColumnEnd = columnStopIndex + 1;
  //     actionBarService.setCurrentRenderedStartColumn(multigridColumnStart);
  //     actionBarService.setCurrentRenderedEndColumn(multigridColumnEnd);

  //     // if (multigridColumnStart === skipToStartColumn) processingService.resetStartColumn();
  //     // }, 500);
  //     // updateRenderedIndices();
  //   },
  //   [actionBarService, processingService.timelineData.start_date]
  // );

  useEffect(() => {
    window.addEventListener('keydown', handleAltDown);
    window.addEventListener('keyup', handleAltUp);
  }, [handleAltDown, handleAltUp]);

  const handleTimelineZoom: React.WheelEventHandler<HTMLDivElement> = (event) => {
    if (event.altKey && event.shiftKey) {
      if (event.deltaY < 0) {
        // Increase zoom factor
        actionBarService.zoomIn();
      } else if (event.deltaY > 0) {
        // Decrease zoom factor
        actionBarService.zoomOut();
      }
      return false;
    }
  };

  const computeCellWidth = (params: Index): number => {
    if (params.index < 1) return labelWidth;
    else return boxWidth;
  };

  const computeCellHeight = (params: Index): number => {
    if (params.index < 1) return DATE_ROW_HEIGHT;
    else return ROW_HEIGHT;
  };

  const handleHoverReset = () => {
    if (hoveredRowIndex !== null || hoveredColumnIndex !== null) {
      _resetHoverCell();
    }
  };

  // Select a the hovered cell when the context menu key is hit, keeping dialog open if already open
  const handleContextMenu = (event: React.MouseEvent) => {
    if (hoveredColumnIndex === 0 || hoveredRowIndex === 0) return; // Return standard context menu if hovering label or header date

    const { isDialogOpen } = actionBarService;
    if (!contextMenuOpen && !isDialogOpen) {
      event.preventDefault();
      setContextMenuOpen(true);
      setSelectedRowIndex(hoveredRowIndex);
      setSelectedColumnIndex(hoveredColumnIndex);
    } else if (!isDialogOpen) {
      // Render standard context menu if dialog isn't open
      closeContextMenu();
    }
  };

  const closeContextMenu = () => {
    setContextMenuOpen(false);
    _resetSelectCell();
  };

  // const _selectCell = ({ scrollToColumn, scrollToRow }: ScrollIndices) => {
  //   setSelectedColumnIndex(scrollToColumn);
  //   setSelectedRowIndex(scrollToRow);
  // };

  const _hoverCell = ({ scrollToColumn, scrollToRow }: ScrollIndices) => {
    if (!actionBarService.isResizing) {
      setHoveredColumnIndex(scrollToColumn);
      setHoveredRowIndex(scrollToRow);
    }
  };

  const _resetSelectCell = () => {
    setSelectedColumnIndex(NULL_NUM);
    setSelectedRowIndex(NULL_NUM);
  };

  const _resetHoverCell = () => {
    setHoveredColumnIndex(NULL_NUM);
    setHoveredRowIndex(NULL_NUM);
  };

  const renderScrollSyncMultiGrid = () => {
    // NOTE: when dealing with hover, refer to this: https://rawgit.com/bvaughn/react-virtualized/master/playground/hover.html
    const columnCount = timelineData.totalDays + 1; // total number of days plus label column
    const rowCount = timelineData.timelines.length + 1; // total number of pipelines plus date row

    // const { skipToStartColumn } = processingService;
    // const lastIndex = columnCount - 1;
    // const startColumn = currentRenderedStartColumn < 0 ? currentRenderedStartColumn : lastIndex;
    // const startColumn = skipToStartColumn >= 0 ? skipToStartColumn : lastIndex;

    return (
      <div style={{ height: '100%' }}>
        <AutoSizer>
          {({ height, width }) => {
            const { gridContainerWidth } = actionBarService;

            // Update grid width in store
            if (width !== gridContainerWidth) actionBarService.setGridContainerWidth(width);

            return (
              <>
                <MultiGrid
                  ref={timelineGrid}
                  cellRenderer={MultiGridCellRenderer}
                  // noContentRenderer={_renderNoContentPlaceholder}
                  noContentRenderer={() => <NoContentPlaceholder />}
                  // onSectionRendered={handleRenderedSection}
                  containerProps={{
                    onWheel: handleTimelineZoom,
                    onMouseLeave: handleHoverReset,
                    onContextMenu: handleContextMenu,
                  }}
                  columnCount={columnCount}
                  columnWidth={computeCellWidth}
                  rowCount={rowCount}
                  rowHeight={computeCellHeight}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  height={height} // Subtract 56px for sort/refresh buttons
                  width={width}
                  // overscanRowCount={10}
                  // overscanColumnCount={10}

                  // scrollToColumn={startColumn}
                  // scrollToColumn={lastIndex}
                  enableFixedColumnScroll
                  enableFixedRowScroll
                  classNameBottomLeftGrid={clsx(LEFT_RIGHT_GRID_CLASSNAME, classes.hideScrollbar)}
                  classNameBottomRightGrid={LEFT_RIGHT_GRID_CLASSNAME}
                  classNameTopRightGrid={clsx(classes.dateHeaderGrid, classes.hideScrollbar)}

                  // Miscellaneous props
                  // onSectionRendered={(params: RenderedSection) => { onSectionRendered(params); }}
                  // scrollToColumn={lastColumn}

                  // Auto scroll-to props (might delete)
                  // scrollToColumn={column_count ? column_count - 1 : scrollToColumn}
                  // scrollToColumn={scrollToColumn}
                  // scrollToRow={scrollToRow}
                  // scrollToColumn={hoveredRowIndex}
                  // scrollToRow={hoveredColumnIndex}
                />
              </>
            );
          }}
        </AutoSizer>
        <DndContext
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          onDragStart={() => {
            actionBarService.setIsResizing(true);
          }}
          onDragEnd={({ delta, ...event }) => {
            actionBarService.setLabelWidth(labelWidth + delta.x);
            actionBarService.setIsResizing(false);
          }}
        >
          <ResizeBar />
          {/* <ResizeBarOverlay /> */}
        </DndContext>
      </div>
    );
  };

  const _renderScrollSyncBox = ({ columnIndex, key, rowIndex, style, isScrolling }: GridCellProps) => {
    return (
      <ProcessRun
        onContextClose={() => closeContextMenu()}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        selectedRowIndex={selectedRowIndex}
        selectedColumnIndex={selectedColumnIndex}
        hoveredRowIndex={hoveredRowIndex}
        hoveredColumnIndex={hoveredColumnIndex}
        key={key}
        style={style}
        // onKeyDown={(event) => {
        //   // console.log(`ProcessRun keyDown key: ${event.key}`)
        //   if (event.key === 'Enter') {
        //     console.log(`ProcessRun keyDown key: ${event.key}`);
        //     // event.currentTarget.focus()

        //     // Get element by hovered row-col id
        //     const status_name = getProcessRunStatus(hoveredRowIndex, hoveredColumnIndex);
        //     const element_id = `${status_name}_${hoveredRowIndex}-${hoveredColumnIndex}`;
        //     const element = document.getElementById(element_id);

        //     // Set focus to that element
        //     element?.focus();

        //     // Set selected cell to hovered row
        //     _selectCell({
        //       scrollToRow: hoveredRowIndex,
        //       scrollToColumn: hoveredColumnIndex,
        //     });
        //   }
        // }}
        onMouseEnter={(event) => {
          _hoverCell({
            scrollToRow: rowIndex,
            scrollToColumn: columnIndex,
          });
        }}
      />
    );
  };

  const MultiGridCellRenderer = (cellProps: GridCellProps) => {
    const { rowIndex, columnIndex, key } = cellProps;

    // TODO: Return the components themselves here, instead of going into another function
    if (columnIndex < 1 && rowIndex < 1) {
      return <TimelineScrollSyncCorner key={cellProps.key} gridCellProps={cellProps} />;
    } else if (rowIndex < 1) {
      return (
        <TimelineScrollSyncHeader
          key={key}
          gridCellProps={cellProps}
          hoveredColumnIndex={hoveredColumnIndex}
          onMouseEnter={() => _hoverCell({ scrollToColumn: columnIndex, scrollToRow: rowIndex })}
        />
      );
    } else if (columnIndex < 1) {
      return (
        <TimelineScrollSyncLabel
          key={key}
          gridCellProps={cellProps}
          hoveredRowIndex={hoveredRowIndex}
          onMouseEnter={() => _hoverCell({ scrollToColumn: columnIndex, scrollToRow: rowIndex })}
        />
      );
    } else {
      return _renderScrollSyncBox(cellProps);
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Paper square className={classes.paper}>
        {renderScrollSyncMultiGrid()}
      </Paper>
    </div>
  );
});

export default Timelines;
