/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Button, Dialog, DialogActions, DialogContent, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { DateTime } from 'luxon';

import { useServices } from 'services';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Text } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';
import { ModalType, ProcessType } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';
import { generatePipelineStandardModalTitle } from 'utils/utils';

import InfoViewerBody from './infoViewer/InfoViewerBody';
import ViewComlogButton from './infoViewer/ViewComlogButton';
import { ModalTitle } from './modals/ModalTitle';
import { ModalDialogProps } from './modals/props';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialog: {
    // height: '100%',
  },
  themeSelect: {
    paddingBottom: theme.spacing(2),
  },
}));

export interface InfoViewerParams {
  /** @deprecated Unused. remove property */
  open: boolean;
  title?: string | null;
  processName: string;
  dataDate: DateTime;
  site: string;
  facility: string;
}

export interface InfoViewerProps extends ModalDialogProps, UseStyles<typeof useStyles> {
  className?: string;
}

const NoDataText = 'No data found.';
const ClosingText = 'Closing...';

const InfoViewer = observer((props: InfoViewerProps): React.ReactElement | null => {
  const classes = useStyles(props);
  const [loading, setLoading] = useState(true);
  const [emptyText, setEmptyText] = useState('No data found.');
  const { actionBarService, processingService } = useServices();

  const { className, modalInfo, onModalClose } = props;
  const { infoViewerParams, infoViewerData } = processingService;

  if (!infoViewerParams) return null;
  const { processName, site, facility, dataDate } = infoViewerParams;

  const open = modalInfo.type === ModalType.InfoViewer;
  const pipelineId: PipelineIdentifier = { process_name: processName, site, facility };
  const pipelineModalTitle = generatePipelineStandardModalTitle(pipelineId);
  const dateString = dataDate.toISODate();
  const showComlogButton = infoViewerData && !infoViewerData.VAP;

  useEffect(() => {
    if (!loading) resetContents();

    processingService
      .fetchDataPipelineInfo({ process_name: processName, site, facility, date: dateString })
      .then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setEmptyText(ClosingText);
    processingService.clearInfoViewerData();
    actionBarService.setInfoViewerOpen(false);

    onModalClose?.(modalInfo.id);
  };

  const resetContents = () => {
    setLoading(true);
    setEmptyText(NoDataText);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog className={classes.dialog} open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <ModalTitle modalTitle="Pipeline Information" modalSubtitle={`${pipelineModalTitle} - ${dateString}`} />

        <DialogContent>
          <Stack justifyContent="center">
            {loading ? (
              <LoadingSpinner spacing={0} />
            ) : infoViewerData ? (
              <Stack rowGap={'2rem'}>
                {Object.values(ProcessType).map(
                  (processType, idx) =>
                    infoViewerData[processType] && (
                      <InfoViewerBody key={idx} pipelineId={{ ...pipelineId, processType }} dataDate={dataDate} />
                    )
                )}
              </Stack>
            ) : (
              <>
                <Text textAlign="center">{emptyText}</Text>
              </>
            )}
          </Stack>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-between' }}>
          {showComlogButton ? (
            <ViewComlogButton pipelineId={pipelineId} logfileParams={{ date: dateString }} />
          ) : (
            <div /> // render empty div to ensure "close" button aligns to right
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default InfoViewer;

// const RawFileBundleSection = () => {
//   return (
//     <div>
//       <Text>Raw File Bundle</Text>
//       {/* <DataGrid/> */}
//     </div>
//   );
// };
