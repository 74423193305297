import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';

export interface LabeledSwitchProps extends SwitchProps {
  label: FormControlLabelProps['label'];
  placement?: FormControlLabelProps['labelPlacement'];
  formControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'control' | 'labelPlacement'>;
}

export const LabeledSwitch = ({ label, placement, formControlLabelProps, ...props }: LabeledSwitchProps) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={placement}
      control={<Switch {...props} />}
      {...formControlLabelProps}
    />
  );
};
