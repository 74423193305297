/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { Stack, TableRowProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { EnhancedTable, EnhancedTableProps } from 'components/timeline/processStateButton/table/EnhancedTable';
import { PipelineErrorWithAction } from 'components/timeline/processStateButton/table/tableUtils';
import { BaseEnhanceTableData } from 'components/timeline/processStateButton/table/types';
import { TableType } from 'utils/constants';
import { PipelineLogError } from 'utils/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

/** @deprecated type no longer used; `onErrorResolved` usage replaced by `onDeleted` and there are currently no means to pass `isErrorHistory` prop to a table row component */
export type ErrorTableRowRendererMiscProps = {
  isErrorHistory?: boolean;
  onErrorResolved?: () => void;
};

export type ErrorTableRowRendererProps<T extends PipelineLogError> = TableRowProps &
  ErrorTableRowRendererMiscProps & {
    errorInfo: T;
  };

type EnhancedPipelineErrorTableData = PipelineLogError & PipelineErrorWithAction & BaseEnhanceTableData;

// export interface ARMFlowTableProps<T extends PipelineLogError | ErrorHistory> extends ComponentPropsWithoutRef<'div'> {
export interface ARMFlowTableProps<T extends EnhancedPipelineErrorTableData> extends ComponentPropsWithoutRef<'div'> {
  tableType: TableType;
  tableHeaders: ReactNode[];

  newTableHeaders?: EnhancedTableProps<T>['headers'];
  selectedRowsState?: Partial<EnhancedTableProps<T>>['selectedRowsState'];
  EnhancedRowRenderer?: EnhancedTableProps<T>['RowRenderer'];
  editableRow?: EnhancedTableProps<T>['editable'];
  disableTable?: EnhancedTableProps<T>['disableTable'];
  defaultSort?: {
    sortBy?: EnhancedTableProps<T>['defaultSortBy'];
    sortDirection?: EnhancedTableProps<T>['defaultSortDirection'];
  };
  /** @deprecated replaced w/ prop in `HeadCell`  */
  enhancedColumnProps?: Partial<EnhancedTableProps<T>>['columnProps'];

  errorList: T[];
  onErrorResolved?: ErrorTableRowRendererProps<T>['onErrorResolved'];

  loading?: boolean;
  /** @deprecated prop not currently used */
  isErrorHistory?: boolean;
  /** @deprecated prop not currently used */
  extraComponent?: ReactNode;
}

const ErrorTable = observer(
  <T extends EnhancedPipelineErrorTableData>({
    tableType,
    tableHeaders: headers,
    newTableHeaders,
    selectedRowsState,
    EnhancedRowRenderer,
    editableRow,
    disableTable,
    defaultSort,
    enhancedColumnProps,
    errorList,
    onErrorResolved,
    loading = false,
    isErrorHistory,
    extraComponent,
    ...props
  }: ARMFlowTableProps<T>): React.ReactElement | null => {
    const { className } = props;

    const classes = useStyles(props);

    return (
      // TODO: revert `Stack` to `div` when I finally remove original table components
      <Stack gap={'1rem'} className={clsx(classes.root, className)} {...props}>
        {extraComponent}

        {/* TODO: Integrate error-resolved props */}
        {newTableHeaders && (
          <EnhancedTable<T>
            data={errorList}
            headers={newTableHeaders}
            loading={loading}
            editable={editableRow}
            disableTable={disableTable}
            defaultSortBy={defaultSort?.sortBy}
            defaultSortDirection={defaultSort?.sortDirection}
            RowRenderer={EnhancedRowRenderer}
            onRowDeleted={onErrorResolved}
            columnProps={enhancedColumnProps}
            selectedRowsState={selectedRowsState}
          />
        )}
      </Stack>
    );
  }
);

export default ErrorTable;
