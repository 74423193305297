/** @jsxImportSource @emotion/react */
import { BaseTextFieldProps, css, InputLabel, TextField, TextFieldProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export type LabeledTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  className?: string;
  label?: string;
  InputLabelProps?: BaseTextFieldProps['InputLabelProps'];
  onChange?: (value: string) => void;
};

export const LabeledTextField = ({
  className,
  label,
  disabled,
  required,
  InputLabelProps,
  onChange,
  ...props
}: LabeledTextFieldProps) => {
  const classes = useStyles(props);

  return (
    <span className={clsx(classes.root, className)}>
      <InputLabel css={[label && labelStyle, disabled && disabledLabelStyle]} required={required} {...InputLabelProps}>
        {label}
      </InputLabel>
      <TextField
        {...props}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
        css={disabled && disabledTextFieldStyle}
      />
    </span>
  );
};

const labelStyle = css`
  height: 1.5rem; // NOTE: use 'margin-top: 1px;' instead if gap between label and text-field feels too large
`;

const disabledLabelStyle = css`
  color: ${grey[700]};
`;

const disabledTextFieldStyle = css`
  input {
    background-color: ${grey[800]};
  }
`;
