import { useServices } from 'services';

import { ErrorHistoryType } from 'utils/constants';

interface UseResolveErrorProps {
  errorType: ErrorHistoryType;
  onErrorResolved?: () => void;
}

type BaseHandleResolveErrorArgs = {
  comment: string;
};

type HandleResolveErrorArgs = BaseHandleResolveErrorArgs & {
  errorId: string;
};

type HandleBulkResolveErrorArgs = BaseHandleResolveErrorArgs & {
  errorIds: string[];
};

export const useResolveError = ({ errorType, onErrorResolved }: UseResolveErrorProps) => {
  const { processingService } = useServices();

  const { resolveError, bulkResolveError } = processingService;

  const handleResolveError = ({ errorId, comment }: HandleResolveErrorArgs) => {
    return resolveError({ errorId, errorType, comment, skipLoading: true }).then(handleAfterErrorResolved);
  };

  const handleBulkResolveError = ({ errorIds, comment }: HandleBulkResolveErrorArgs) => {
    return bulkResolveError({ errorIds, errorType, comment, skipLoading: true }).then(handleAfterErrorResolved);
  };

  const handleAfterErrorResolved = () => {
    if (onErrorResolved) {
      onErrorResolved();
    }
  };

  return { handleResolveError, handleBulkResolveError };
};
