import React from 'react';

import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { BaseEnhanceTableData, DataColumnKey, HeadCell } from './types';
import { Order } from './types';

export interface EnhancedTableHeadProps<Data extends BaseEnhanceTableData> {
  headers: readonly HeadCell<Data>[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: DataColumnKey<Data>) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;

  disableTable?: boolean;
}

export const EnhancedTableHead = <T extends BaseEnhanceTableData>(props: EnhancedTableHeadProps<T>) => {
  const { headers, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, disableTable } = props;

  const createSortHandler = (property: DataColumnKey<T>) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!rowCount || disableTable}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>

        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            {...headCell.columnCellProps}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disableSort}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
