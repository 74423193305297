import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { ExpandLess } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
  Menu,
  Stack,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import _ from 'lodash';

import { useServices } from 'services';

import { Text } from 'components/styles';
import { PruneDataFormJSON } from 'models/PruneDataForm';
import { ReleaseDataFormJSON } from 'models/ReleaseDataForm';
import { RunBundlerFormJSON } from 'models/RunBundlerForm';
import { RunProcessFormJSON } from 'models/RunProcessForm';
import { ArmflowFormJSON, ArmflowFormJSONKey } from 'models/types';
import { UseStyles } from 'styles/utilityTypes';
import { ARMFlowForms } from 'utils/constants';

const FormHistoryBorderWidth: number = 2;

const useStyles = makeStyles((theme) => ({
  root: {},
  menuHeader: {
    margin: theme.spacing(1, 2),
  },
  menuItem: {
    backgroundColor: grey[900],
  },
  list: {
    padding: 0,
    margin: theme.spacing(0, 1),
  },
  listItem: {
    // List item text classname
    [`& .${listItemTextClasses.primary}`]: {
      fontFamily: 'monospace',
    },
    [`& .${listItemTextClasses.secondary}`]: {
      fontFamily: 'monospace',
    },
  },
  listItemGroup: {
    backgroundColor: grey[900],
    border: `solid ${FormHistoryBorderWidth}px ${grey[900]}`,
  },
  nestedList: {
    border: `solid ${FormHistoryBorderWidth}px ${grey[900]}`,
    borderTop: 'none',
    marginBottom: theme.spacing(0.5),
  },
  nestedListItem: {
    backgroundColor: '#333333',
  },
  nestedListItemText: {
    padding: '0 1.5em',
  },
}));

export interface FormHistoryProps extends UseStyles<typeof useStyles> {
  form: ARMFlowForms;
  disabled?: boolean;
  onSelected?: (formJSON: ArmflowFormJSON) => void;
  className?: string;
}

// const reprocessingKey: ArmflowFormJSONKey = 'is_reprocessing';
const commentKey: ArmflowFormJSONKey = 'comment';
const secondaryLabelKeys: ArmflowFormJSONKey[] = ['comment', 'command_line_args'];
type FormHistoryLabel = {
  primary: string | null;
  secondary: string | null;
};

type FormKeyMap = {
  [ARMFlowForms.RUN_PROCESS]: Array<keyof Extract<ArmflowFormJSON, RunProcessFormJSON>>;
  [ARMFlowForms.RELEASE_DATA]: Array<keyof Extract<ArmflowFormJSON, ReleaseDataFormJSON>>;
  [ARMFlowForms.PRUNE_DATA]: Array<keyof Extract<ArmflowFormJSON, PruneDataFormJSON>>;
  [ARMFlowForms.RUN_BUNDLER]: Array<keyof Extract<ArmflowFormJSON, RunBundlerFormJSON>>;
};

const FieldsToGroupBy: FormKeyMap = {
  [ARMFlowForms.RUN_PROCESS]: ['process_name', 'location_name', 'processing_id'],
  [ARMFlowForms.RELEASE_DATA]: ['process_name', 'location_name', 'processing_id'],
  [ARMFlowForms.PRUNE_DATA]: ['process_name', 'location_name'],
  [ARMFlowForms.RUN_BUNDLER]: ['process_name', 'location_name'],
};

const FieldsToPrintFromGrouped: FormKeyMap = {
  [ARMFlowForms.RUN_PROCESS]: ['start_date', 'end_date', 'command_line_args'],
  [ARMFlowForms.RELEASE_DATA]: ['process_name', 'location_name', 'processing_id', 'comment'],
  [ARMFlowForms.PRUNE_DATA]: ['start_date', 'end_date'],
  [ARMFlowForms.RUN_BUNDLER]: ['process_name', 'location_name'],
};

/**
 * Button to render list of saved form data
 * @todo Need to distinguish boolean fields (namely 'reprocessing')
 */
const FormHistory = observer((props: FormHistoryProps): React.ReactElement | null => {
  const { className, disabled, form: formType, onSelected } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const classes = useStyles(props);
  const { storageService } = useServices();
  const { formHistory } = storageService;

  const history = formHistory && formHistory[formType];
  const historyExists = history && history.length;

  // Group form items to create categorized list headers
  const historyGrouped = !history
    ? []
    : _.groupBy<ArmflowFormJSON>(history, (formData) => {
        if (!formData) return null;
        if (typeof formData === 'number') return null;

        const fields = FieldsToGroupBy[formType];
        const groupKey = fields
          .map((fieldName) => formData[fieldName as keyof typeof formData])
          .filter((val) => !!val) // filter out any potential null/undefined/empty-string values
          .join(' - ');

        return groupKey;
      });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAutoFillForm = (formData: ArmflowFormJSON) => {
    if (onSelected) onSelected(formData);
    handleClose();
  };

  /** Clear history and close menu */
  const handleClearFormHistory = () => {
    setAnchorEl(null);
    storageService.clearFormHistory(formType);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip title="Form History" placement="top">
        <IconButton disabled={!historyExists || disabled} onClick={handleClick}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      <Menu MenuListProps={{ dense: true }} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* header & clear button */}
        <Stack className={classes.menuHeader} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Text medium>Form History</Text>
          <Button size="small" onClick={handleClearFormHistory}>
            Clear
          </Button>
        </Stack>

        {/* List items */}
        <List className={classes.list}>
          {/* Use historyGrouped entries to create nested list */}
          {Object.entries(historyGrouped).map(([key, formDataList], idx1) => (
            <FormHistoryItem
              key={key}
              index={idx1}
              formDataList={formDataList}
              formType={formType}
              titleText={key}
              onFormItemClicked={handleAutoFillForm}
            />
          ))}
        </List>
      </Menu>
    </div>
  );
});

export default FormHistory;

interface FormHistoryItemProps extends UseStyles<typeof useStyles> {
  index: number;
  titleText: string;
  formType: FormHistoryProps['form'];
  formDataList: ArmflowFormJSON[];
  onFormItemClicked: (formData: ArmflowFormJSON) => void;
}

const FormHistoryItem = (props: FormHistoryItemProps) => {
  const { index, formDataList, formType, titleText, onFormItemClicked } = props;
  const [open, setOpen] = useState(index === 0); // Always open the first group by default
  const classes = useStyles(props);

  // Convert grouped form data into the strings to render
  const fieldStringList = formDataList.map((formData) => {
    return FieldsToPrintFromGrouped[formType].map((fieldName) => {
      const key = fieldName as ArmflowFormJSONKey;
      const data = formData[fieldName as keyof typeof formData];
      const label: FormHistoryLabel = {
        primary: null,
        secondary: null,
      };

      if (data && secondaryLabelKeys.includes(key)) {
        // Surround with quotations if it's comment
        label.secondary = key === commentKey ? `"${data}"` : data;
      } else {
        label.primary = data;
      }
      return label;
    });
  });

  return (
    <>
      <ListItemButton
        dense
        className={clsx(classes.listItem, classes.listItemGroup)}
        title={titleText}
        onClick={() => setOpen(!open)}
      >
        <ListItemText primary={`${index + 1}) ${titleText}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding className={classes.nestedList}>
          {fieldStringList.map((item, idx2) => {
            const data = formDataList[idx2]; // Form list index of string list should align with data list
            const combineKeys = (labelKey: keyof FormHistoryLabel) =>
              item
                .map((v) => v[labelKey])
                .filter((v) => !!v)
                .join(' - ');

            const primaryText = combineKeys('primary');
            const secondaryText = combineKeys('secondary');

            return (
              <ListItemButton
                dense
                className={clsx(classes.listItem, classes.nestedListItem)}
                key={idx2}
                onClick={() => onFormItemClicked(data)}
              >
                <ListItemText className={classes.nestedListItemText} primary={primaryText} secondary={secondaryText} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
