import { useTheme } from '@mui/material';

import { LoadingSpinner } from './LoadingSpinner';

interface SubmitButtonTextProps {
  buttonText?: string;
  isSubmitting: boolean;
}

export const SubmitButtonText = ({ buttonText = 'Submit', isSubmitting }: SubmitButtonTextProps) => {
  const theme = useTheme();
  return isSubmitting ? <LoadingSpinner size={theme.typography.fontSize} /> : <>{buttonText}</>;
};
