import { TableCell, TableCellProps } from '@mui/material';

import { BaseEnhancedTableRowRendererProps, BaseEnhanceTableData, DataColumnKey } from './types';

export const EnhancedTableRowRenderer = <T extends BaseEnhanceTableData>({
  entries,
  rowData,
  customPropsSelector,
  firstColumnProps = {},
}: BaseEnhancedTableRowRendererProps<T>) => {
  return (
    <>
      {entries.map(([_key, value], idx) => {
        const key = _key as DataColumnKey<T>;
        const customProps = customPropsSelector?.(key);
        const _firstColumnProps: TableCellProps = idx === 0 ? firstColumnProps : {};

        return (
          <TableCell key={idx} {..._firstColumnProps} {...customProps}>
            {value}
          </TableCell>
        );
      })}
    </>
  );
};
