/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  css,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  Stack,
  styled,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { Text } from 'components/styles';
import { AnchorPoints, CommonPopoverProps } from 'utils/constants';

import { ActionLink, ActionLinkProps } from './ActionLink';
import { LabeledTextField } from './LabeledTextField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ResolveErrorPopoverProps extends ComponentPropsWithoutRef<'span'> {
  commentProps: {
    commentText: string;
    setCommentText?: (comment: string) => void;
  };
  onResolveError?: (comment: string) => void;
  actionLinkProps?: ActionLinkProps;
}

export const ResolveErrorPopover = ({
  className,
  commentProps,
  onResolveError,
  actionLinkProps,
  ...props
}: ResolveErrorPopoverProps) => {
  const classes = useStyles(props);

  return (
    <PopupState variant="popover" popupId="resolveErrorCommentInput">
      {(popupState) => (
        <span className={clsx(classes.root, className)} {...props}>
          <div>
            <ActionLink {...actionLinkProps} {...bindTrigger(popupState)}>
              Resolve Error
            </ActionLink>
          </div>
          <Popover {...CommonPopoverProps} {...bindPopover(popupState)} {...AnchorPoints.BottomLeft}>
            <ResolveErrorAddComment
              commentProps={commentProps}
              onResolveError={onResolveError}
              onClose={() => {
                popupState.close();
              }}
            />
          </Popover>
        </span>
      )}
    </PopupState>
  );
};

type ResolveErrorAddCommentProps = {
  commentProps?: ResolveErrorPopoverProps['commentProps'];
  onResolveError?: ResolveErrorPopoverProps['onResolveError'];
  onClose?: () => void;
};

const ResolveErrorAddComment = ({ commentProps, onResolveError, onClose }: ResolveErrorAddCommentProps) => {
  const [_comment, _setComment] = useState<string>();

  // Decide whether to use controlled or internal state
  const { commentText, setCommentText } = commentProps || {};
  const [comment, setComment] = [commentText || _comment, setCommentText || _setComment];

  const commentTrimmed = comment?.trim();
  const disableSubmit = !commentTrimmed;
  const showClearButton = !comment?.length; // Display clear-button even if text is only whitespace

  const handleClearComment = () => {
    setComment('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!disableSubmit) {
      onResolveError?.(commentTrimmed);
      onClose?.();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack css={[popoverContentStyle]}>
        <DialogTitle>
          <Text>Specify Reason for Change</Text>
        </DialogTitle>
        <DialogContent>
          <CommentInputContainer>
            <LabeledTextField
              size="small"
              label="Comment"
              value={comment}
              required
              fullWidth
              multiline
              onChange={setComment}
              helperText={disableSubmit && 'Must specify reason to resolve error'}
              InputProps={{
                endAdornment: (
                  <IconButton css={showClearButton && hiddenClearInputStyle} onClick={handleClearComment}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </CommentInputContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" disabled={disableSubmit} type="submit">
            Resolve
          </Button>
        </DialogActions>
      </Stack>
    </form>
  );
};

const popoverContentStyle = css`
  /* height: 300px; */
  width: 400px;
`;

const hiddenClearInputStyle = css`
  visibility: hidden;
`;

const CommentInputContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
  `
);
