import { useCallback, useState } from 'react';

export type UseCopyTextHandlerProps = {
  text: string;
  copiedText?: string;
  tooltipText?: string;
};

export const useCopyTextHandler = (props: UseCopyTextHandlerProps) => {
  const { text, copiedText = text, tooltipText } = props;
  const [showCheckmark, setShowCheckmark] = useState(false);

  const copiedStatusText = showCheckmark ? 'Copied to clipboard!' : tooltipText ?? 'Click to Copy';

  const handleCopy = useCallback(() => {
    if (!showCheckmark) {
      setTimeout(() => setShowCheckmark(false), 2000);
    }
    navigator.clipboard.writeText(copiedText.trim());
    setShowCheckmark(true);
  }, [copiedText, showCheckmark]);

  return { handleCopy, showCheckmark, copiedStatusText };
};
