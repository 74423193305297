import { action, computed, makeObservable, observable } from 'mobx';

import autoBind from 'auto-bind';
import _ from 'lodash';
import { DateTime } from 'luxon';

import {
  ARMFlowForms,
  BASE_BOX_WIDTH,
  DEFAULT_LABEL_WIDTH,
  DRAWER_CLOSED_WIDTH,
  DRAWER_WIDTH,
  MAX_ZOOM,
  MIN_ZOOM,
  MINIMUM_LABEL_WIDTH,
  ModalType,
  PROCESS_INTERVAL_START_COLUMN_INDEX,
  SettingsVisualToggleKey,
  VisualToggleDefaults,
} from 'utils/constants';
import { SettingsVisualToggle } from 'utils/types';
import { getDateFromOffset, getOffsetFromDate, roundTwoDecimals } from 'utils/utils';

import DrawerService from './DrawerService';
import ModalsService from './ModalsService';
import StorageService from './StorageService';

/**
 * The Action Bar of the ARMFlow dashboard handles functions related to UI switches and toggles.
 * As such, this service should never be dependent on another service. This will allow other services
 * to toggle UI elements via the actions provided here.
 */

enum ViewerTypes {
  Info = 'infoViewer',
  Log = 'logViewer',
}

type ActionBarServiceProps = {
  storageService: StorageService;
  drawerService: DrawerService;
  modalsService: ModalsService;
};

/** Represents what was formerly the keys of the ActionBarService.openViewers object */
const VIEWER_MODALS: ModalType[] = [ModalType.InfoViewer, ModalType.LogViewer];

/** @todo Consider renaming to something broader, as this service generally represents the UI visual state */
export default class ActionBarService {
  private storageService: StorageService;
  private drawerService: DrawerService;
  private modalsService: ModalsService;

  // Timeline Grid settings
  isResizing: boolean = false;
  labelWidth: number = DEFAULT_LABEL_WIDTH;

  // Zoom settings
  zoomFactor: number = 7.0;
  sliderZoomFactor: number = this.zoomFactor;
  zoomMode: boolean = false;
  /** @todo not using this. Delete */
  zoomAutoFit: boolean = false;
  gridContainerWidth: number | null = null;

  // Settings Display toggles
  visualToggles: Record<SettingsVisualToggleKey, SettingsVisualToggle> = VisualToggleDefaults;

  // Action Bar settings
  openFormConfirms: Record<ARMFlowForms, boolean> = {
    [ARMFlowForms.RUN_PROCESS]: false,
    [ARMFlowForms.RELEASE_DATA]: false,
    [ARMFlowForms.PRUNE_DATA]: false,
    [ARMFlowForms.RUN_BUNDLER]: false,
  };
  disableConfirmButton: boolean = false;
  validationLoading: boolean = false;

  // TODO: Month Nav settings
  currentRenderedStartDate: DateTime = DateTime.now();
  currentRenderedEndDate: DateTime = DateTime.now();
  currentRenderedStartColumn: number = -1;
  currentRenderedEndColumn: number = 0;
  skipToStartColumn: number = -1;

  // ActionMenu dialog states
  /** @deprecated Viewers are now all rendered from the ModalsService  */
  openViewers: Record<ViewerTypes, boolean> = {
    infoViewer: false,
    logViewer: false,
  };

  constructor({ storageService, drawerService, modalsService }: ActionBarServiceProps) {
    this.storageService = storageService;
    this.drawerService = drawerService;
    this.modalsService = modalsService;

    this._loadVisualToggles();

    // Set MobX observables
    makeObservable(this, {
      // Zoom and render variables
      isResizing: observable,
      labelWidth: observable,
      visualToggles: observable,
      zoomFactor: observable,
      sliderZoomFactor: observable,
      zoomMode: observable,
      zoomAutoFit: observable,
      openFormConfirms: observable,
      disableConfirmButton: observable,
      validationLoading: observable,
      openViewers: observable,

      currentRenderedStartDate: observable,
      currentRenderedEndDate: observable,
      currentRenderedStartColumn: observable,
      currentRenderedEndColumn: observable,
      skipToStartColumn: observable,

      isFormOpen: computed,
      isDialogOpen: computed,
      intervalCellWidth: computed,
      intervalContainerWidth: computed,

      // Zoom and render actions
      setIsResizing: action,
      setLabelWidth: action,
      refreshLabelWidth: action,
      resetLabelWidth: action,
      toggleVisualDisplay: action,
      _adjustZoom: action,
      adjustSliderZoom: action,
      adjustZoom: action,
      autoAdjustZoom: action,
      zoomIn: action,
      zoomOut: action,
      zoomReset: action,
      zoomModeToggle: action,
      setZoomAutoFit: action,

      // Generic dialog state actions
      setFormDialogOpen: action,
      setFormConfirmationOpen: action,
      setViewerOpen: action,
      closeAllDialogs: action,

      // Specific dialog state actions
      setRunProcessConfirmationOpen: action,
      setReleaseDataConfirmationOpen: action,
      setRunBundlerConfirmationOpen: action,
      setDisableConfirmButton: action,
      setValidationLoading: action,
      setInfoViewerOpen: action,
      setLogViewerOpen: action,

      // Actions related to month-by-month navigation (work-in-progress)
      setCurrentRenderedStartDate: action,
      setCurrentRenderedEndDate: action,
      setSkipToStartColumn: action,
      resetStartColumn: action,
      setCurrentRenderedStartColumn: action,
      setCurrentRenderedEndColumn: action,
      goBackMonth: action,
      goBackMonthCol: action,
      goMonthForward: action,
    });

    // Automatically bind `this` for all class methods
    autoBind(this);
  }

  /**
   * Loads visual toggles saved by the browser, using defaults if none exists
   */
  private _loadVisualToggles() {
    for (const key in this.visualToggles) {
      const toggleKey = key as SettingsVisualToggleKey;
      const savedPreferences = this.storageService.savedPreferences;

      if (savedPreferences && savedPreferences[toggleKey]) {
        const activeByDefault = VisualToggleDefaults[toggleKey].active;
        this.visualToggles[toggleKey].active = savedPreferences[toggleKey]?.active ?? activeByDefault;
      }
    }
  }

  private _getViewerModalType(viewer: ViewerTypes): ModalType | null {
    switch (viewer) {
      case ViewerTypes.Info:
        return ModalType.InfoViewer;

      case ViewerTypes.Log:
        return ModalType.LogViewer;
      default:
        return null;
    }
  }

  get isFormOpen(): boolean {
    return (
      Object.values(this.openFormConfirms).some((isOpen) => !!isOpen) || this.modalsService.hasModal(ModalType.Form)
    );
  }

  get isDialogOpen(): boolean {
    return this.isFormOpen || this.modalsService.hasModal(VIEWER_MODALS);
  }

  get openForms(): Record<ARMFlowForms, boolean> {
    return {
      [ARMFlowForms.RUN_PROCESS]: this.modalsService.hasForm(ARMFlowForms.RUN_PROCESS),
      [ARMFlowForms.RELEASE_DATA]: this.modalsService.hasForm(ARMFlowForms.RELEASE_DATA),
      [ARMFlowForms.PRUNE_DATA]: this.modalsService.hasForm(ARMFlowForms.PRUNE_DATA),
      [ARMFlowForms.RUN_BUNDLER]: this.modalsService.hasForm(ARMFlowForms.RUN_BUNDLER),
    };
  }

  get intervalCellWidth(): number {
    return BASE_BOX_WIDTH * this.zoomFactor;
  }

  get intervalContainerWidth(): number {
    // TODO: I think we *need* to stick to using DEFAULT_LABEL_WIDTH constant instead of this.labelWidth. Might need to inspect later
    return this.gridContainerWidth ? this.gridContainerWidth - DEFAULT_LABEL_WIDTH : 0;
  }

  get MaxLabelWidth(): number {
    const drawerWidth = this.drawerService.drawerOpen ? DRAWER_WIDTH : DRAWER_CLOSED_WIDTH;
    return window.innerWidth - drawerWidth - 100;
  }

  setIsResizing = (isResizing: ActionBarService['isResizing']) => {
    this.isResizing = isResizing;
  };

  setLabelWidth = (width: ActionBarService['labelWidth']) => {
    const maxWidth = Math.max(this.MaxLabelWidth, MINIMUM_LABEL_WIDTH); // Using Math.max() in case computed MaxLabelWidth is greater than minimum
    this.labelWidth = width;
    if (width < MINIMUM_LABEL_WIDTH) {
      this.labelWidth = MINIMUM_LABEL_WIDTH;
    } else if (width > maxWidth) {
      this.labelWidth = maxWidth;
    }
  };

  refreshLabelWidth = () => this.setLabelWidth(this.labelWidth);
  resetLabelWidth = () => this.setLabelWidth(DEFAULT_LABEL_WIDTH);

  toggleVisualDisplay = (visualKey: SettingsVisualToggleKey): void => {
    const isActive = !this.visualToggles[visualKey].active;
    this.visualToggles[visualKey].active = isActive;

    this.storageService.setVisualPreference(visualKey, isActive);
  };

  adjustSliderZoom = (newZoomFactor: number): void => {
    const rounded_zoom = roundTwoDecimals(newZoomFactor);
    // console.log(`rounded_zoom: ${rounded_zoom}`);
    // console.log(`adjusting zoom: ${rounded_zoom}`);

    if (rounded_zoom < MIN_ZOOM || rounded_zoom <= 0) this.sliderZoomFactor = MIN_ZOOM;
    else if (rounded_zoom > MAX_ZOOM) this.sliderZoomFactor = MAX_ZOOM;
    else this.sliderZoomFactor = rounded_zoom;
  };

  _adjustZoom = (newZoomFactor: number): void => {
    const rounded_zoom = roundTwoDecimals(newZoomFactor);
    // TODO: remove; redundant with method above
    if (rounded_zoom < MIN_ZOOM || rounded_zoom <= 0) this.zoomFactor = MIN_ZOOM;
    // TODO: remove; redundant with method above
    else if (rounded_zoom > MAX_ZOOM) this.zoomFactor = MAX_ZOOM;
    else this.zoomFactor = rounded_zoom;
  };

  adjustZoom = _.debounce(this._adjustZoom, 250);

  autoAdjustZoom = (totalDays: number, skipWait = false) => {
    const { intervalContainerWidth: currentContainerWidth, adjustSliderZoom, _adjustZoom } = this;

    // Compute width to fit all cells
    if (currentContainerWidth) {
      const maxBoxWidth = currentContainerWidth / totalDays;
      const maxZoomToFit = _.floor(maxBoxWidth / BASE_BOX_WIDTH, 1);

      if (skipWait) _adjustZoom(maxZoomToFit);
      adjustSliderZoom(maxZoomToFit); // always override current zoom
    }
  };

  zoomIn = (): void => {
    if (this.sliderZoomFactor < MAX_ZOOM) this.adjustSliderZoom(this.sliderZoomFactor + 0.1);
    else {
      // console.log('At max zoom. Zoom not adjusted.');
    }
  };

  zoomOut = (): void => {
    if (this.sliderZoomFactor > MIN_ZOOM) this.adjustSliderZoom(this.sliderZoomFactor - 0.1);
    else {
      // console.log('At max zoom. Zoom not adjusted.');
    }
  };

  zoomReset = (): void => {
    // console.log(`Resetting zoom to default (${(7.0).toFixed(1)})`);
    this.adjustZoom(7.0);
  };

  zoomModeToggle(isOn: boolean): void {
    // console.log(`Toggling zoom mode: (${isOn})`);
    this.zoomMode = isOn;
  }

  /** @todo not using this. Delete */
  setZoomAutoFit(isAuto: boolean) {
    this.zoomAutoFit = isAuto;
  }

  setGridContainerWidth(width: number) {
    this.gridContainerWidth = width;
  }

  setFormDialogOpen(form: ARMFlowForms, open: boolean) {
    if (open) {
      this.modalsService.pushModal({ id: form, title: null, type: ModalType.Form, conditionalKey: form });
    } else {
      this.modalsService.popModal(form);
    }
  }

  setFormConfirmationOpen(form: ARMFlowForms, open: boolean) {
    this.openFormConfirms[form] = open;
  }

  /** @deprecated Use `setFormConfirmationOpen(form: ARMFlowForms, open: boolean)` */
  setRunProcessConfirmationOpen(open: boolean) {
    this.openFormConfirms[ARMFlowForms.RUN_PROCESS] = open;
  }

  /** @deprecated Use `setFormConfirmationOpen(form: ARMFlowForms, open: boolean)` */
  setReleaseDataConfirmationOpen(open: boolean) {
    this.openFormConfirms[ARMFlowForms.RELEASE_DATA] = open;
  }

  /** @deprecated Use `setFormConfirmationOpen(form: ARMFlowForms, open: boolean)` */
  setPruneDataConfirmationOpen(open: boolean) {
    this.openFormConfirms[ARMFlowForms.PRUNE_DATA] = open;
  }

  /** @deprecated Use `setFormConfirmationOpen(form: ARMFlowForms, open: boolean)` */
  setRunBundlerConfirmationOpen(open: boolean) {
    this.openFormConfirms[ARMFlowForms.RUN_BUNDLER] = open;
  }

  setDisableConfirmButton(disable: boolean) {
    this.disableConfirmButton = disable;
  }

  setValidationLoading(isLoading: boolean) {
    this.validationLoading = isLoading;
  }

  setViewerOpen(viewer: ViewerTypes, open: boolean) {
    if (!(viewer in this.openViewers)) throw new Error('Attempted to set a non-existing viewer.');
    this.openViewers[viewer] = open;

    const modalType = this._getViewerModalType(viewer);
    if (modalType) {
      if (open) this.modalsService.pushModal({ id: viewer, title: null, type: modalType });
      else this.modalsService.popModal(viewer);
    }
  }

  setInfoViewerOpen(open: boolean) {
    this.openViewers.infoViewer = open;

    const modalId = ViewerTypes.Info;
    if (open) this.modalsService.pushModal({ id: modalId, type: ModalType.InfoViewer, title: null });
    else this.modalsService.popModal(modalId);
  }

  setLogViewerOpen(open: boolean) {
    this.openViewers.logViewer = open;

    const modalId = ViewerTypes.Log;
    if (open) this.modalsService.pushModal({ id: modalId, type: ModalType.LogViewer, title: null });
    else this.modalsService.popModal(modalId);
  }

  setCurrentRenderedStartDate(date: DateTime) {
    this.currentRenderedStartDate = date;
  }

  setCurrentRenderedEndDate(date: DateTime) {
    this.currentRenderedEndDate = date;
  }

  setSkipToStartColumn(index: number) {
    this.skipToStartColumn = index;
  }

  resetStartColumn() {
    this.skipToStartColumn = -1;
  }

  setCurrentRenderedStartColumn(index: number) {
    this.currentRenderedStartColumn = index;
  }

  setCurrentRenderedEndColumn(index: number) {
    this.currentRenderedEndColumn = index;
  }

  goBackMonth(timelineStartDate: DateTime | null) {
    const startDate = timelineStartDate;
    // const startDate = this.timelineData.start_date;

    // const newDate = new Date(this.currentRenderedStartDate);
    // const date = this.currentRenderedStartDate.getUTCDate();
    // if (date !== 1) {
    //   newDate.setUTCDate(1);
    // } else {
    //   newDate.setUTCDate(1);
    //   newDate.setUTCMonth(newDate.getUTCMonth() - 1);
    // }

    // const newDate = new Date(this.currentRenderedStartDate);
    // const date = this.currentRenderedStartDate.getDate();
    // if (date !== 1) {
    //   newDate.setDate(1);
    // } else {
    //   newDate.setDate(1);
    //   newDate.setMonth(newDate.getMonth() - 1);
    // }

    if (this.currentRenderedStartDate === startDate) return;
    const luxDate = this.currentRenderedStartDate;
    let newLuxDate: DateTime;
    if (luxDate.toUTC().day !== 1) {
      newLuxDate = luxDate.startOf('month');
    } else {
      newLuxDate = luxDate.toUTC().startOf('month').minus({ months: 1 });
    }
    // console.log(luxDate.toISODate());

    // const newDate = luxDate.toJSDate();
    const newDate = newLuxDate;

    if (startDate) {
      // const utcDate = DateTime.fromJSDate(newDate).toUTC().toJSDate();
      // this.setCurrentRenderedStartDate(newDate);
      // this.setCurrentRenderedStartDate(utcDate);

      let dateOffset: number;
      // if (newDate > this.timelineStartDate) {
      if (startDate < newDate) {
        this.setCurrentRenderedStartDate(newDate);
        dateOffset = getOffsetFromDate(startDate, newDate);
      } else {
        this.setCurrentRenderedStartDate(startDate);
        // startColumn = getOffsetFromDate(this.timelineStartDate, this.timelineStartDate);
        dateOffset = 0;
      }

      this.currentRenderedStartColumn = 1 + dateOffset;
    }
  }

  goBackMonthCol(timelineStartDate: DateTime | null) {
    // Get current date and set to beginning of month. Return associated column
    // If current date is beginning of month, set to beginning of previous month. Return associated column

    const startDate = timelineStartDate;
    if (!startDate) return; // Bail if there's startDate is null/undefined

    // If current date (or column?) is beginning of timeline, return column for start date
    if (this.currentRenderedStartColumn <= PROCESS_INTERVAL_START_COLUMN_INDEX) {
      this.setSkipToStartColumn(PROCESS_INTERVAL_START_COLUMN_INDEX);
      return;
    }

    const renderedStartDate = getDateFromOffset(startDate, this.currentRenderedStartColumn - 1);
    const luxDate = renderedStartDate;

    // Calculate new startDate
    let newLuxDate: DateTime;
    if (luxDate.day !== 1) {
      // If date not beginning of the month, set new date to such
      newLuxDate = luxDate.startOf('month');
    } else {
      // Subtract one month if at beginning of month
      newLuxDate = luxDate.startOf('month').minus({ months: 1 });
    }

    const newColumnIndex = getOffsetFromDate(startDate, newLuxDate, 1);

    // If beginning of current/previous month is before startDate, return column for start date
    if (newColumnIndex <= PROCESS_INTERVAL_START_COLUMN_INDEX) {
      this.setSkipToStartColumn(PROCESS_INTERVAL_START_COLUMN_INDEX);
    } else {
      this.setSkipToStartColumn(newColumnIndex);
    }
  }

  goMonthForward(timelineStartDate: DateTime | null) {
    // const newDate = new Date(this.currentRenderedStartDate);
    // newDate.setUTCDate(1);
    // newDate.setUTCMonth(newDate.getUTCMonth() + 1);

    const luxDate = this.currentRenderedStartDate;
    // const newLuxDate: DateTime = luxDate.plus({ month: 1 }).startOf('month');
    // const newDate = luxDate.plus({ month: 1 }).startOf('month').toJSDate();
    // console.log(newLuxDate.toISODate());

    const newDate = luxDate.plus({ month: 1 }).startOf('month');

    if (timelineStartDate) {
      this.setCurrentRenderedStartDate(newDate);
      this.currentRenderedStartColumn = getOffsetFromDate(timelineStartDate, newDate, 1);
    }
  }

  closeAllDialogs() {
    // Close all forms
    for (const form of Object.values(ARMFlowForms)) {
      const armflowForm = form as ARMFlowForms;
      if (this.openForms[armflowForm]) this.setFormConfirmationOpen(armflowForm, false); // Close form input dialog
      if (this.openFormConfirms[armflowForm]) this.setFormDialogOpen(armflowForm, false); // Close form confirmation dialog
    }

    // Close all viewers
    this.modalsService.removeModalByType(VIEWER_MODALS); // Remove all viewer-related IDs from the modal stack
  }
}
