import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { TabContext } from '@mui/lab';
import { Tab, Tabs, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useServices } from 'services';

import { UseStyles } from 'styles/utilityTypes';
import { ProcessType, ProcessTypeLabel } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface ErrorHistoryTabsProps extends UseStyles<typeof useStyles> {
  activeTab?: ProcessType;
  defaultTab?: ProcessType;
  showAllOpenErrors?: boolean;
  onChange?: (processType: ProcessType) => void;

  className?: string;
}

const ErrorHistoryTabs = observer(
  ({
    defaultTab,
    activeTab,
    showAllOpenErrors,
    onChange,
    ...props
  }: ErrorHistoryTabsProps): React.ReactElement | null => {
    const { className } = props;
    const { processingService } = useServices();
    const { selectedPipelineId } = processingService;
    const pipelineProcessType = selectedPipelineId?.processType;

    const [currentTab, setCurrentTab] = useState<ProcessType | undefined>(defaultTab ?? pipelineProcessType);

    const classes = useStyles(props);

    const tab = activeTab ?? currentTab;
    const isIngest = pipelineProcessType === ProcessType.INGEST || showAllOpenErrors;
    const isVap = pipelineProcessType === ProcessType.VAP || showAllOpenErrors;

    return (
      <div className={clsx(classes.root, className)}>
        <TabContext value={tab ?? ''}>
          <Tabs
            value={tab}
            onChange={(_, historyType: ProcessType) => {
              if (onChange) onChange(historyType);

              // Ignore state updates if using state from props
              if (!activeTab) {
                return setCurrentTab(historyType);
              }
            }}
          >
            {/* <Tabs/> component doesn't accept React fragments as children, so ingest checks must be done individually */}
            {isIngest && <Tab label={ProcessTypeLabel[ProcessType.Collection]} value={ProcessType.Collection} />}
            <Tab label={ProcessTypeLabel[ProcessType.TimeCheck]} value={ProcessType.TimeCheck} />
            {isIngest && <Tab label={ProcessTypeLabel[ProcessType.BUNDLE]} value={ProcessType.BUNDLE} />}
            {isIngest && <Tab label={ProcessTypeLabel[ProcessType.INGEST]} value={ProcessType.INGEST} />}

            {isVap && <Tab label={ProcessTypeLabel[ProcessType.VAP]} value={ProcessType.VAP} />}

            <Tab label={ProcessTypeLabel[ProcessType.ReleaseData]} value={ProcessType.ReleaseData} />
          </Tabs>
        </TabContext>
      </div>
    );
  }
);

export default ErrorHistoryTabs;
