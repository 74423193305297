export const transparentWhite = (opacity: number) => `rgba(255, 255, 255, ${opacity})`;
export const transparentBlack = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;

const ColorTokens = {
  DialogBackground: 'hsl(0, 0%, 22%)',
  BasePaperBackground: 'hsl(0, 0%, 13%)',
};

export const UtilityStyles = {
  Colors: { ...ColorTokens },
};
