/** @jsxImportSource @emotion/react */
import { CSSProperties } from 'react';

import { SvgIconComponent } from '@mui/icons-material';
import { ButtonProps, IconButton, Tooltip, TooltipProps } from '@mui/material';

import { CssProp } from 'styles/utilityTypes';

type PartialTooltipProps = Omit<TooltipProps, 'children' | 'title' | 'placement'>;
type PartialButtonProps = Omit<ButtonProps, 'disabled' | 'onClick'>;

type InheritedButtonProps = Pick<ButtonProps, 'disabled' | 'onClick'>;
type InheritedTooltipProps = Pick<TooltipProps, 'placement'>;

export interface TooltipButtonProps extends InheritedButtonProps, InheritedTooltipProps {
  Icon: SvgIconComponent;
  tooltipText?: TooltipProps['title'];
  color?: CSSProperties['color'];

  tooltipProps?: PartialTooltipProps;
  buttonProps?: PartialButtonProps;

  buttonCss?: CssProp['css'];
  tooltipCss?: CssProp['css'];
}

export const TooltipButton = ({
  Icon,

  tooltipText,
  disabled,
  placement,
  color,
  onClick,

  // additional button-related props
  buttonProps,
  buttonCss,

  // additional tooltip-related props
  tooltipProps,
  tooltipCss,
}: TooltipButtonProps) => {
  return (
    <Tooltip css={tooltipCss} title={tooltipText} placement={placement} {...tooltipProps}>
      <IconButton css={buttonCss} onClick={onClick} disabled={disabled} {...buttonProps}>
        <Icon css={{ color }} />
      </IconButton>
    </Tooltip>
  );
};
