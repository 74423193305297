import { ReactNode } from 'react';

import { useServices } from 'services';

import { ActionLink } from 'components/common/ActionLink';
import { CopyText } from 'components/common/CopyText';
import { FileDownloadTypes, LogTypeLabel, ProcessStatusLabel } from 'utils/constants';
import { LogfileParameters, RunInfoProcessStatus } from 'utils/types';

import InfoStatusIcon from './InfoStatusIcon';
import { InfoViewerTextPopover } from './InfoViewerTextPopover';
import { InfoViewerBodyProps } from './types';

export interface ProcessStatusRowContentsProps extends InfoViewerBodyProps {
  processStatus: Partial<RunInfoProcessStatus>;
}

export const ProcessStatusRowContents = ({
  processStatus,
  dataDate,
  pipelineId,
}: ProcessStatusRowContentsProps): ReactNode[] => {
  const { processingService } = useServices();

  const {
    logfile_keys = [],
    processing_id,
    ran_by,
    ran_on,
    run_directory,
    status = ProcessStatusLabel.SUCCESS,
    status_message,
  } = processStatus;

  const isRunning = status === ProcessStatusLabel.RUNNING;

  const logfileParams: LogfileParameters = {
    date: dataDate.toISODate(),
    processing_id: isRunning ? processing_id : undefined,
  };

  const statusTableRowContents = [
    <InfoStatusIcon key={'statusIcon'} status={status} />,
    <InfoViewerTextPopover
      trigger="hover"
      key={'statusHover'}
      text={status}
      popoverId={'infoViewerStatusPopover'}
      popoverText={status_message}
    />,
    processing_id,
    run_directory ? <CopyText key="runDir" text={run_directory} /> : 'N/A',
    ran_on,
    ran_by,
    <div key="logfiles">
      {logfile_keys.map((_fileType) => {
        const fileType =
          isRunning && _fileType === FileDownloadTypes.PROCESS_LOG ? FileDownloadTypes.REALTIME_PROCESS_LOG : _fileType;

        return (
          <div key={fileType}>
            <ActionLink onClick={() => processingService.fetchLogFile(pipelineId, logfileParams, fileType)}>
              View {LogTypeLabel[fileType]}
            </ActionLink>
          </div>
        );
      })}
    </div>,
  ];

  return statusTableRowContents;
};
