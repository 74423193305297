import React, { useMemo, useState } from 'react';

import { css, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { LabeledTextFieldProps } from 'components/common/LabeledTextField';
import { UtilityStyles } from 'components/styles';

export type BulkActionInfo = {
  actionName: string;
  actionHelperText?: string;

  textFieldLabel: string;
  textFieldHelperText?: string;
  textFieldProps?: Omit<LabeledTextFieldProps, 'onChange' | 'value' | 'disabled' | 'helperText'>;
};

export enum ActionType {
  RESOLVE_ERROR = 'resolve-error',
}

export const formDisabledStyle = css`
  background-color: hsl(0, 0%, 17%);
`;
export const formEnabledStyle = css`
  background-color: ${grey[900]};
  background-color: ${UtilityStyles.Colors.BasePaperBackground};
`;

const bulkActionSectionStyle = css`
  background-color: ${UtilityStyles.Colors.DialogBackground};
`;
const bulkActionFieldStyle = css`
  display: flex;
  justify-content: center;
  padding-bottom: 1.25rem;
`;

export const BulkActionStyles = {
  formDisabled: formDisabledStyle,
  formEnabled: formEnabledStyle,
  bulkActionSection: bulkActionSectionStyle,
  bulkActionField: bulkActionFieldStyle,
};

// TODO: fix type so action and selector text are decoupled
export const PipelineErrorBulkActions: Record<ActionType, BulkActionInfo> = {
  [ActionType.RESOLVE_ERROR]: {
    actionName: 'Resolve Error',
    textFieldLabel: 'Comment',
    textFieldHelperText: 'Must specify reason to resolve error',
    textFieldProps: {
      multiline: true,
      required: true,
      InputProps: {
        // TODO: move `endAdornment` to .tsx file so I can define clear icon here (or consider always showing)
        // endAdornment: null,
      },
    },
  },
};

type UseBulkActionHandlerProps = {
  actions: Record<string, BulkActionInfo>;
  onBulkAction?: (args: { text: string; identifiers: string[] }) => Promise<void>;
};

export type UseBulkActionHandlerReturn = ReturnType<typeof useBulkActionHandler>;

export const useBulkActionHandler = ({ actions, onBulkAction }: UseBulkActionHandlerProps) => {
  const [selectedAction, _setSelectedAction] = useState<string | null>(null);
  const [selectedRows, _setSelectedRows] = useState<readonly number[]>([]);
  const [actionTextValue, _setActionText] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();

  const actionInfo = useMemo(
    () => Object.values(actions).find((v) => v.actionName === selectedAction) ?? null,
    [actions, selectedAction]
  );

  const textTrimmed = actionTextValue?.trim();
  const showClearButton = !actionTextValue?.length; // Display clear-button even if text is only whitespace

  const disableSubmit = !selectedRows.length || !selectedAction?.trim() || !textTrimmed || isSubmitting;
  const disableActionSelect = !selectedRows.length;
  const disableActionField = disableActionSelect || !selectedAction?.trim();

  const bulkActionTitleCss = {
    padding: theme.spacing(1, 2),
    color: disableActionSelect ? grey[500] : undefined,
  };

  const handleSetSelectedRows = (items: readonly number[]) => {
    _setSelectedRows(items);

    if (!items.length) {
      handleClearAction();
    }
  };
  const handleClearSelectedRows = () => {
    _setSelectedRows([]);
  };

  const handleSetActionText = (text: string) => {
    _setActionText(text);
  };

  const handleSetAction = (action: string | null) => {
    _setSelectedAction(action);
  };

  const handleClearAction = () => {
    _setSelectedAction(null);
    handleClearActionText();
  };

  const handleClearActionText = () => {
    _setActionText('');
  };

  const _toggleSubmissionState = (state = !isSubmitting) => {
    setIsSubmitting(state);
  };

  const handleSubmitBulkAction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    _toggleSubmissionState(true);
    if (!disableSubmit) {
      onBulkAction?.({ text: textTrimmed, identifiers: selectedRows.map((v) => v.toString()) }).finally(() => {
        // Clear all fields following submission
        _toggleSubmissionState(false);
        handleClearAction();
        handleClearSelectedRows();
      });
    }
  };

  return {
    selectedRows,
    actionTextValue,
    actionInfo,

    showClearButton,
    isSubmitting,

    disableSubmitAction: disableSubmit,
    disableActionSelect,
    disableActionField,

    bulkActionTitleCss,

    handleSetSelectedRows,
    handleClearSelectedRows,

    handleSetAction,
    handleClearAction,

    handleSetActionText,
    handleClearActionText,

    handleSubmitBulkAction,
  };
};
