/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { TableCell, TableCellProps } from '@mui/material';

import { TableStyles } from 'components/timeline/processStateButton/table/tableStyles';
import { BaseEnhancedTableRowRendererProps, HeadCell } from 'components/timeline/processStateButton/table/types';
import { PipelineLogError } from 'utils/types';

interface EnhancedErrorTableCellProps {
  columnKey: keyof PipelineLogError;
  customPropsSelector: BaseEnhancedTableRowRendererProps<PipelineLogError>['customPropsSelector'];
  // headCells: HeadCell<PipelineErrorWithAction>[];
  headCells: HeadCell<PipelineLogError>[];
  columnIndex: number;
  firstColumnProps: TableCellProps;
  value: ReactNode;
}

export function EnhancedErrorTableCell({
  columnKey,
  customPropsSelector,
  headCells,
  columnIndex,
  firstColumnProps,
  value,
}: EnhancedErrorTableCellProps) {
  const customProps = customPropsSelector?.(columnKey);

  const columnInfo = headCells.find((header) => header.id === columnKey);
  const disableRowSelection = !!columnInfo?.disableRowSelection;
  const disablePadding = !!columnInfo?.disablePadding;
  const columnCellProps = columnInfo?.columnCellProps;

  const _firstColumnProps: TableCellProps = columnIndex === 0 ? firstColumnProps : {};

  return (
    <TableCell
      key={columnIndex}
      css={disableRowSelection && TableStyles.nonPropagationCellStyle}
      padding={disablePadding ? 'none' : 'normal'}
      {..._firstColumnProps}
      {...customProps}
      {...columnCellProps}
      onClick={(event) => {
        if (disableRowSelection) {
          event.stopPropagation();
        }
        customProps?.onClick?.(event);
      }}
    >
      {value}
    </TableCell>
  );
}
