/** @jsxImportSource @emotion/react */

/** originally based on MUI's table example: https://mui.com/material-ui/react-table/#sorting-amp-selecting */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
  Checkbox,
  css,
  Paper,
  PaperProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  TableRowProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

import clsx from 'clsx';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Text } from 'components/styles';

import { EnhancedTableHead, EnhancedTableHeadProps } from './EnhancedTableHead';
import { EnhancedTableRowRenderer } from './EnhancedTableRowRenderer';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { getComparator, stableSort } from './tableUtils';
import {
  BaseEnhancedTableRowRendererProps,
  BaseEnhanceTableData,
  CustomTableCellProps,
  DataColumnKey,
  Order,
} from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

// type SelectedRowState = [ readonly number[], (selected: readonly number[]) => void]
type SelectedRowState = { selectedRows: readonly number[]; setSelectedRows: (selected: readonly number[]) => void };

export interface EnhancedTableProps<Data extends BaseEnhanceTableData> extends PaperProps {
  data: Data[];
  headers: EnhancedTableHeadProps<Data>['headers'];
  selectedRowsState?: SelectedRowState;
  RowRenderer?: (props: BaseEnhancedTableRowRendererProps<Data>) => EmotionJSX.Element;
  onRowDeleted?: BaseEnhancedTableRowRendererProps<Data>['onDeleted'];

  useTitleToolbar?: boolean;
  tableTitle?: string;
  noDataText?: string;
  stickyHeader?: boolean;

  onClearFields?: () => void;
  /** Mapping of column key names to associated cell props */
  columnProps?: Partial<Record<keyof Data, CustomTableCellProps>>;
  editable?: {
    isEditableFunc: (entry: Data) => boolean;
    readOnlyText?: string | ((data: Data) => string);
  };
  loading?: boolean;
  disableTable?: boolean;

  // Sorting
  defaultSortDirection?: Order;
  defaultSortBy?: string; // TODO: figure out typing to constrain to `DataColumnKey<Data>`
}

/**
 * @todo Do we want/need pagination?
 */
export const EnhancedTable = observer(
  <T extends BaseEnhanceTableData>({
    className,

    ...props
  }: EnhancedTableProps<T>) => {
    const classes = useStyles(props);
    const {
      data,
      headers: _headers,
      selectedRowsState,
      RowRenderer = EnhancedTableRowRenderer,
      onRowDeleted,
      onClearFields,
      tableTitle,
      stickyHeader = true,
      useTitleToolbar = false,
      editable,
      columnProps,
      noDataText = 'No Records Found',
      loading = false,
      disableTable = false,
      defaultSortDirection = 'asc',
      defaultSortBy = 'id',
    } = props;

    const theme = useTheme();
    const { selectedRows, setSelectedRows } = selectedRowsState ?? {};

    const [order, setOrder] = useState<Order>(defaultSortDirection);
    // const [orderBy, setOrderBy] = useState<keyof ProcessStateTableData>('changedOn');
    const [orderBy, setOrderBy] = useState(defaultSortBy);
    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const [selected, setSelected] = useState<readonly number[]>([]);
    const [selected, setSelected] =
      selectedRows && setSelectedRows ? [selectedRows, setSelectedRows] : useState<readonly number[]>([]);
    // const [page, setPage] = useState(0);
    // const [dense, setDense] = useState(false);
    // const [rowsPerPage, setRowsPerPage] = useState(5);

    const editableData = useMemo(() => {
      const isEditableFunc = editable?.isEditableFunc;

      return data.filter((entry) => (isEditableFunc ? isEditableFunc(entry) : true));
    }, [data, editable]);

    const clearFields = useCallback(() => {
      onClearFields?.();
      // TODO: Execute any clear-field functions (probably need to pass function as prop)
      // throw new NotImplementedError('Execute any clear-field functions (probably need to pass function as prop)');
    }, [onClearFields]);

    const clearSelection = () => {
      setSelected([]);
    };

    useEffect(() => {
      if (!selected.length) {
        clearFields();
      }
    }, [clearFields, selected.length]);

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {

    // const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ProcessStateTableData) => {
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: DataColumnKey<T>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = editableData.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
      // onSetSelected(newSelected)
    };

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleChangePage = (event: unknown, newPage: number) => {
    //   setPage(newPage);
    // };

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   setPage(0);
    // };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    // TODO: #CommentedForReference Planning to reference code below for future generalization
    // const visibleRows = useMemo(
    //   // () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    //   () =>
    //     data
    //       ? stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //       : [],
    //   [data, order, orderBy, page, rowsPerPage]
    // );

    const sortedRows = useMemo(
      // () => (data ? stableSort(data, getComparator(order, orderBy)) : []),
      () => (data ? stableSort(data, getComparator(order, orderBy)) : []),
      // TODO: #CommentedForReference Planning to reference code below for future generalization
      // () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      // data.sort(getComparator(order, orderBy))
      [data, order, orderBy]
    );

    const headers = [..._headers];
    /**
     * FILL IN ABOVE FROM `ProcessStateTable.tsx`
     */

    return (
      <Stack gap={'0.5rem'}>
        {!useTitleToolbar && tableTitle && <Text css={{ padding: theme.spacing(0, 2) }}>{tableTitle}</Text>}
        <Paper
          className={clsx(classes.root, className)}
          elevation={4}
          sx={{ width: '100%', paddingBottom: theme.spacing(1) }}
          {...props}
        >
          {useTitleToolbar && <EnhancedTableToolbar numSelected={selected.length} tableLabel={tableTitle} />}
          <TableContainer css={tableContainerStyle}>
            <Table css={[tableStyle, stickyHeader && stickyHeaderStyle]} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead<T>
                disableTable={disableTable}
                headers={headers}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={editableData.length}
              />
              <TableBody>
                {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
                {/* {visibleRows.map((row, index) => { */}
                {!loading &&
                  sortedRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    // Extract editable status
                    const isEditable = editable ? !!editable.isEditableFunc(row) : true;
                    // const isEditable = false;
                    const _readOnlyText = editable?.readOnlyText;
                    const readOnlyText =
                      typeof _readOnlyText === 'function' ? _readOnlyText(row) : _readOnlyText ?? 'Read-only';
                    const editableTooltipText = isEditable ? undefined : readOnlyText;
                    const editProps: Partial<TableRowProps> =
                      isEditable && !disableTable
                        ? { sx: { cursor: 'pointer' }, onClick: (event) => handleClick(event, row.id) }
                        : { sx: { [`.${tableCellClasses.root}`]: { color: theme.palette.text.disabled } } };

                    // Separate row data from the `id`
                    // const { id, ...rowData } = row;
                    // const rowEntries = Object.entries(rowData);

                    // Generate cell entries
                    const { ...rowData } = row;
                    const rowEntries: BaseEnhancedTableRowRendererProps<T>['entries'] = _headers.map((header) => {
                      const columnKey = header.id;
                      return [columnKey, rowData[columnKey]];
                    });

                    return (
                      <Tooltip arrow key={row.id} placement="left" title={editableTooltipText}>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          // className={tableRowClasses.hover}
                          {...editProps}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              disabled={!isEditable || disableTable}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>

                          <RowRenderer
                            rowData={rowData}
                            entries={rowEntries}
                            disabled={disableTable}
                            firstColumnProps={{ component: 'th', id: labelId, scope: 'row', padding: 'none' }}
                            customPropsSelector={(key) => columnProps?.[key] ?? {}}
                            onDeleted={onRowDeleted}
                          />
                        </TableRow>
                      </Tooltip>
                    );
                  })}
                {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>

          {/* TODO: #CommentedForReference Planning to reference code below for future generalization */}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

          {/* Fallback if loading or no records are found */}
          {(loading || !sortedRows.length) && (
            <Text italic padding={theme.spacing(5)} textAlign="center">
              {loading ? <LoadingSpinner /> : noDataText}
            </Text>
          )}
        </Paper>
      </Stack>
    );
  }
);

const tableContainerStyle = css`
  /* overflow-x: initial; */
  overflow-y: auto;
  max-height: 60vh;
`;

const tableStyle = css`
  min-width: 750px;

  thead {
    background-color: ${grey[800]};
  }
  tbody {
    background-color: ${grey[900]};
  }
`;

const stickyHeaderStyle = css`
  thead {
    /* Sticky header */
    position: sticky;
    top: 0;
    z-index: 10;
  }
`;

const actionCellStyle = css`
  /* background-color: rgba(255, 255, 255, 0.08); */
  cursor: default;
`;
