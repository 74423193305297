import { css } from '@emotion/react';

const nonPropagationCellStyle = css`
  cursor: default;
  &:hover {
    /* background-color: rgb(255, 255, 255, 0.075); */
    background-color: rgb(0, 0, 0, 0.25);
  }
`;

export const TableStyles = {
  nonPropagationCellStyle,
};
