import { Link, LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ActionLinkProps = {
  disabled?: boolean;
} & LinkProps;

export const ActionLink = ({ disabled, ...linkProps }: ActionLinkProps) => {
  const { href, onClick, ...disabledProps } = linkProps;

  return disabled ? <ActionLinkDisabled {...disabledProps} underline="none" /> : <ActionLinkEnabled {...linkProps} />;
};

export const ActionLinkEnabled = styled(Link)`
  :hover {
    cursor: pointer;
  }
  &:active {
    filter: brightness(80%);
  }
`;

const ActionLinkDisabled = styled(Link)`
  :hover {
    cursor: default;
  }
  filter: brightness(50%);
`;
