import { RunModeSelectionOptions } from 'utils/constants';
import { RunModeDetailsResponse } from 'utils/types';

import { ProcessStateTableData } from './types';

export function transformProcessStateData(data: RunModeDetailsResponse | null): ProcessStateTableData[] {
  if (!data) {
    return [];
  }

  const transformedData = Object.entries(data).map(([key, value], index) => {
    const { process_type, state: run_mode, changed_on, description } = value;
    const currentState = Object.keys(RunModeSelectionOptions).find((key) => RunModeSelectionOptions[key] === run_mode);

    const entry: ProcessStateTableData = {
      // id: key,
      id: index,
      processType: process_type,
      // state: run_mode && run_mode in RunMode ? RunMode[run_mode] : '',
      state: currentState ?? '',
      changedOn: changed_on ?? '',
      description: description ?? '',
    };
    return entry;
  });

  return transformedData;
}
