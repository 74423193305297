import { TableCellProps } from '@mui/material';

import { PipelineErrorState, ProcessType, TableType } from 'utils/constants';
import { PipelineLogError } from 'utils/types';

import { BaseEnhanceTableData, HeadCell, Order, ProcessStateTableData } from './types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: {
    [key in Key]: number | string;
  },
  b: {
    [key in Key]: number | string;
  }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * @returns `true` if the given `processType` isn't within list of read-only process state types
 * @todo Considering constraining `processType` of type `string` to `ProcessType` enum instead
 */
export function processStateIsEditable(row: ProcessStateTableData): boolean {
  const { processType } = row;
  return !ReadOnlyProcessTypes.includes(processType as ProcessType);
}
const ReadOnlyProcessTypes = [ProcessType.Collection, ProcessType.TimeCheck];

export function pipelineErrorIsEditable(row: PipelineLogError): boolean {
  const { state } = row;
  return state !== PipelineErrorState.RESOLVED;
}

const AdjustedPaddingProps: TableCellProps = {
  padding: 'none',
  sx: { padding: '1rem .25rem ' },
};
const NoWrapProps: TableCellProps = {
  /* width: 250,  */
  padding: 'none',
  ...AdjustedPaddingProps,
  sx: { ...AdjustedPaddingProps.sx, whiteSpace: 'nowrap' },
};

type ActionsColumn = {
  actions?: never;
};

type ActionCellHeadCell = HeadCell<BaseEnhanceTableData & ActionsColumn>;
export const ActionCellHeader: ActionCellHeadCell = {
  id: 'actions',
  label: 'Actions',
  numeric: false,
  disablePadding: true,
  disableSort: true,
  disableRowSelection: true,
  columnCellProps: { width: 250, ...AdjustedPaddingProps },
};

export type PipelineErrorWithAction = PipelineLogError & ActionsColumn;

export const ErrorModalHeadCells: Partial<Record<TableType, HeadCell<PipelineErrorWithAction>[]>> = {
  [TableType.SystemErrors]: [
    {
      id: 'proc_type',
      numeric: false,
      disablePadding: true,
      label: 'Process Type',
      columnCellProps: NoWrapProps,
    },
    {
      id: 'error_level',
      numeric: false,
      label: 'Level',
      columnCellProps: AdjustedPaddingProps,
    },
    {
      id: 'error_code',
      numeric: false,
      label: 'Error Codes',
    },
    {
      id: 'error_message',
      numeric: false,
      label: 'Message',
      disableRowSelection: true,
    },
    {
      id: 'latest_occurrence',
      numeric: false,
      label: 'Latest Occurrence	(UTC)',
      columnCellProps: NoWrapProps,
    },
    {
      id: 'state',
      numeric: false,
      label: 'State',
    },
    ActionCellHeader,
  ],

  [TableType.PipelineError]: [
    {
      id: 'proc_type',
      numeric: false,
      label: 'Process Type',
      columnCellProps: NoWrapProps,
    },
    {
      id: 'processing_id',
      numeric: false,
      label: 'Processing ID',
    },
    {
      id: 'error_level',
      numeric: false,
      label: 'Level',
      columnCellProps: AdjustedPaddingProps,
    },
    {
      id: 'error_code',
      numeric: false,
      label: 'Error Codes',
      columnCellProps: NoWrapProps,
    },
    {
      id: 'error_message',
      numeric: false,
      label: 'Message',
      disableRowSelection: true,
    },
    {
      id: 'latest_occurrence',
      numeric: false,
      label: 'Latest Occurrence	(UTC)',
      columnCellProps: NoWrapProps,
    },
    {
      id: 'state',
      numeric: false,
      label: 'State',
    },
    {
      id: 'assigned_to',
      numeric: false,
      label: 'Assignee',
      columnCellProps: AdjustedPaddingProps,
    },
    {
      id: 'comments',
      numeric: false,
      label: 'Comments',
      columnCellProps: AdjustedPaddingProps,
    },
    ActionCellHeader,
  ],
};
