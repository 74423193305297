import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { FormikHelpers, useFormik } from 'formik';

import { useServices } from 'services';

import { useArmflowFormSubmitter } from 'hooks/useArmflowFormSubmitter';
import { RUN_BUNDLER_EMPTY_FORM, RunBundlerFormFields } from 'models/RunBundlerForm';
import { UseStyles } from 'styles/utilityTypes';
import { ARMFlowForms, ModalType, ProcessType } from 'utils/constants';

import AutocompleteLocation from '../../autocomplete/AutocompleteLocation';
import AutocompleteProcessName from '../../autocomplete/AutocompleteProcessName';
import { ArmflowFormDialog, useArmflowFormUpdater } from '../common/ArmflowFormDialog';
import ResetButton from '../form/ResetButton';
import RunBundlerFormConfirm from './RunBundlerFormConfirm';
import { RunBundlerFormTokens } from './schema';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formStyle: {
    width: 550,
  },
  formControl: {
    padding: theme.spacing(1, 0),
  },
}));

export interface RunBundlerFormDialogProps
  extends Omit<ArmflowFormDialog, 'validatorPath'>,
    UseStyles<typeof useStyles> {
  className?: string;
}

export const RunBundlerFormDialog = observer((props: RunBundlerFormDialogProps): React.ReactElement | null => {
  const { className, modalInfo } = props;

  const classes = useStyles(props);
  const { actionBarService, processingService } = useServices();
  const { handleCloseForm, closeConfirmation, confirmSubmit } = useArmflowFormSubmitter({
    formType: ARMFlowForms.RUN_BUNDLER,
  });

  const { runBundlerDialogForm } = processingService;
  const { openFormConfirms } = actionBarService;

  // const formOpen = openForms[ARMFlowForms.RUN_BUNDLER];
  const formOpen = modalInfo.type === ModalType.Form && modalInfo.conditionalKey === ARMFlowForms.RUN_BUNDLER;
  const confirmationOpen = openFormConfirms[ARMFlowForms.RUN_BUNDLER];
  const { processName, processType, locationName } = runBundlerDialogForm;
  const { formIds, validationSchema } = RunBundlerFormTokens;

  const initialValues: RunBundlerFormFields = {
    processName: processName ?? RUN_BUNDLER_EMPTY_FORM.processName,
    processType: processType ?? RUN_BUNDLER_EMPTY_FORM.processType,
    locationName: (locationName ?? RUN_BUNDLER_EMPTY_FORM.locationName)?.toUpperCase() ?? null,
  };

  // Autofill form fields only once, on-open
  useEffect(() => {
    processingService.autofillRunBundlerForm({ ...initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: RunBundlerFormFields, formikHelper: FormikHelpers<RunBundlerFormFields>) => {
    const { processName, locationName } = values;
    runBundlerDialogForm.updateForm({ processName, locationName });
    actionBarService.setRunBundlerConfirmationOpen(true);
    formikHelper.setSubmitting(false);
  };

  // Initialize Formik
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });
  const { values, touched, errors, setFieldValue, setFieldTouched, submitCount, isSubmitting } = formik;

  // Get special handler function for updating our Formik forms
  const handleFormUpdate = useArmflowFormUpdater(setFieldValue, setFieldTouched, submitCount);

  const reset = () => {
    formik.resetForm({ values: RUN_BUNDLER_EMPTY_FORM });
    runBundlerDialogForm.reset();
  };

  return (
    <div className={clsx(classes.root, className)}>
      {/* Form confirmation dialog */}
      {confirmationOpen && (
        <RunBundlerFormConfirm
          open={confirmationOpen}
          onClose={closeConfirmation}
          onCancel={closeConfirmation}
          onSubmit={confirmSubmit}
        />
      )}

      {/* Form dialog */}
      <Dialog
        open={formOpen}
        onClose={(_, reason) => {
          if (reason === 'escapeKeyDown') {
            handleCloseForm();
          }
        }}
      >
        <form className={classes.formStyle} onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogTitle className={classes.dialogTitle}>
            Run Bundler
            <Stack direction="row">
              <ResetButton disabled={isSubmitting} onClick={reset} />
            </Stack>
          </DialogTitle>

          <DialogContent dividers>
            {/* PCM Process Name selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessName
                  id={formIds.processName}
                  value={values.processName}
                  disabled={isSubmitting}
                  onChange={(value, processType) =>
                    handleFormUpdate([
                      { formId: formIds.processName, value },
                      { formId: formIds.locationName, value: null, skipTouch: true }, // Clear location field when process name changes
                      { formId: formIds.processType, value: processType },
                    ])
                  }
                  error={touched.processName && Boolean(errors.processName)}
                  helperText={touched.processName && errors.processName}
                  filterType={ProcessType.INGEST}
                />
              </FormControl>
            </FormGroup>
            {/* Location selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteLocation
                  id={formIds.locationName}
                  disabled={!values.processName || isSubmitting}
                  processName={values.processName}
                  value={values.locationName}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.locationName, value })}
                  error={touched.locationName && Boolean(errors.locationName)}
                  helperText={touched.locationName && errors.locationName}
                />
              </FormControl>
            </FormGroup>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseForm}>Cancel</Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={isSubmitting || (!formik.isValid && formik.submitCount > 0)}
            >
              Run Bundler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
});
