import { ReactNode } from 'react';

import { action, makeObservable, observable } from 'mobx';

import autoBind from 'auto-bind';

import { ARMFlowForms, ModalType, TableType } from 'utils/constants';

export type ModalInfo<ConditionKey = string | number> = {
  id: string;
  type: ModalType;
  title: string | null;
  subtitle?: string;

  message?: string;

  errorTableType?: TableType;
  customModalData?: Record<string, unknown>;

  conditionalKey?: ConditionKey;

  /** WIP - starting to refactor modal system to support pushing through components */
  customComponent?: {
    component: ReactNode; // TODO: may need to change type to React.Component rather than ReactNode if issues with onClose arise
    onClose?: () => void;
  };
};

export default class ModalsService {
  @observable modalStack: ModalInfo[] = [];

  constructor() {
    makeObservable(this);

    // Automatically bind `this` for all class methods
    autoBind(this);
  }

  get topModal(): ModalInfo | null {
    return this.modalStack.at(-1) ?? null;
  }

  hasModal(type: ModalType | ModalType[], id?: string): boolean {
    return this.modalStack.some((modalInfo) => {
      const doesTypeMatch = Array.isArray(type) ? type.includes(modalInfo.type) : modalInfo.type === type;
      const doesIdMatch = id ? modalInfo.id === id : true; // Always true if undefined as `id` is optional

      return doesTypeMatch && doesIdMatch;
    });
  }

  hasForm(form: ARMFlowForms): boolean {
    return this.modalStack.some((modalInfo) => {
      const { type, conditionalKey: formType } = modalInfo;
      return type === ModalType.Form && formType === form;
    });
  }

  @action
  pushModal(payload: ModalInfo) {
    const { id } = payload;
    if (!this.modalStack.some((modal_info) => modal_info.id === id)) {
      this.modalStack.push({ ...payload });
    }
  }

  @action
  popModal(modalId?: string) {
    // take 'id' as parameter, then iterate from end-of-array until 'id' is found
    // return this.modalStack.pop()

    let latest_modal: ModalInfo | null = null;
    for (let i = this.modalStack.length - 1; i >= 0; i--) {
      // Start from the end of array
      if (this.modalStack[i].id === modalId) {
        latest_modal = this.modalStack.splice(i, 1)[0];
      }
    }

    return latest_modal;
  }

  @action
  removeModalByType(modalType: ModalType | ModalType[]) {
    this.modalStack = this.modalStack.filter((info) =>
      Array.isArray(modalType) ? !modalType.includes(info.type) : info.type !== modalType
    );
  }

  @action
  updateModalById(modalId: string, payload: Omit<ModalInfo, 'id'>, merge = false) {
    const modalIndex = this.modalStack.findIndex((value) => value.id === modalId);

    // const currentInfo = this.modalStack[currentInfoIndex]
    const currentInfo = this.modalStack.at(modalIndex);
    if (!currentInfo) {
      console.warn(`modalId '${modalId}' not found in modalStack`);
      return;
    }

    if (merge) {
      const updatedModalInfo: ModalInfo = { ...currentInfo, ...payload };
      this.modalStack[modalIndex] = updatedModalInfo;
    } else {
      this.modalStack[modalIndex] = { id: modalId, ...payload };
    }
  }
}
