/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';

import { css, Paper, styled, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Text, UtilityStyles } from 'components/styles';

export interface InfoViewerTableProps extends React.ComponentPropsWithoutRef<'div'> {
  tableTitle: ReactNode;
  headers: (string | null)[];
  rows: ReactNode[][];
}

const MAX_TABLE_HEIGHT = 515;
const _InfoViewerTable = ({ tableTitle, headers, rows, ...props }: InfoViewerTableProps) => {
  return (
    <div {...props}>
      <Text paddingTop={'1rem'}>{tableTitle}</Text>
      <TableContainer component={Paper} style={{ maxHeight: MAX_TABLE_HEIGHT }}>
        <Table stickyHeader>
          <InfoTableHeader>
            <TableRow>
              {headers.map((value, idx) => (
                <TableCell key={idx}>{value}</TableCell>
              ))}
            </TableRow>
          </InfoTableHeader>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((value, colIndex) => (
                  <TableCell key={colIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const InfoViewerTable = styled(_InfoViewerTable)(
  ({ theme }) => css`
    /* width: 80%; */

    padding: 0 4rem;

    .${tableCellClasses.head} {
      // Remove background-color override added by 'stickyHeader' prop
      background-color: ${UtilityStyles.Colors.BasePaperBackground};
    }

    .${tableCellClasses.body} {
      background-color: ${theme.palette.grey[900]};
    }
  `
);

const InfoTableHeader = styled(TableHead)`
  .t {
    padding: 0;
  }
`;
