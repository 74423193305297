import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import { FormikHelpers, useFormik } from 'formik';

import { useServices } from 'services';

import { useArmflowFormSubmitter } from 'hooks/useArmflowFormSubmitter';
import PruneDataForm, { PRUNE_DATA_EMPTY_FORM, PruneDataFormFields } from 'models/PruneDataForm';
import { ArmflowFormJSON } from 'models/types';
import { UseStyles } from 'styles/utilityTypes';
import { ARMFlowForms, ModalType } from 'utils/constants';
import { generateSiteAndFacilityFromLocation } from 'utils/utils';

import AutocompleteLocation from '../../autocomplete/AutocompleteLocation';
import AutocompleteProcessName from '../../autocomplete/AutocompleteProcessName';
import { ArmflowFormDialog, useArmflowFormUpdater } from '../common/ArmflowFormDialog';
import AutocompleteProcessingId from '../fields/AutocompleteProcessingId';
import FormDateRangePicker from '../fields/FormDateRangePicker';
import FormHistory from '../fields/FormHistory';
import ValidationBox from '../fields/ValidationBox';
import ResetButton from '../form/ResetButton';
import PruneDataFormConfirm from './PruneDataFormConfirm';
import { PruneDataFormTokens } from './schema';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formStyle: {
    width: 550,
  },
  formControl: {
    padding: theme.spacing(1, 0),
  },
}));

export interface PruneDataFormDialogProps extends ArmflowFormDialog, UseStyles<typeof useStyles> {
  className?: string;
}

const { formIds, validationSchema } = PruneDataFormTokens;
export const PruneDataFormDialog = observer((props: PruneDataFormDialogProps): React.ReactElement | null => {
  const { className, validatorPath, modalInfo } = props;

  const classes = useStyles(props);
  const { actionBarService, adiApiService, processingService } = useServices();
  const { handleCloseForm, closeConfirmation, confirmSubmit } = useArmflowFormSubmitter({
    formType: ARMFlowForms.PRUNE_DATA,
    validatorPath,
  });

  const { pruneDataDialogForm } = processingService;
  const { openFormConfirms, validationLoading } = actionBarService;

  // const formOpen = openForms[ARMFlowForms.PRUNE_DATA];
  const formOpen = modalInfo.type === ModalType.Form && modalInfo.conditionalKey === ARMFlowForms.PRUNE_DATA;
  const confirmationOpen = openFormConfirms[ARMFlowForms.PRUNE_DATA];
  const { processName, processType, locationName, processingId, startDate, endDate, pruneAll } = pruneDataDialogForm;

  const initialValues: PruneDataFormFields = {
    processName: processName ?? PRUNE_DATA_EMPTY_FORM.processName,
    processType: processType ?? PRUNE_DATA_EMPTY_FORM.processType,
    locationName: (locationName ?? PRUNE_DATA_EMPTY_FORM.locationName)?.toUpperCase() ?? null,
    processingId: processingId ?? PRUNE_DATA_EMPTY_FORM.processingId,
    pruneAll: pruneAll ?? PRUNE_DATA_EMPTY_FORM.pruneAll,
    startDate: startDate ?? PRUNE_DATA_EMPTY_FORM.startDate,
    endDate: endDate ?? PRUNE_DATA_EMPTY_FORM.endDate,
  };

  // Autofill form fields only once, on-open
  useEffect(() => {
    processingService.autofillPruneDataForm({ ...initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: PruneDataFormFields, formikHelpers: FormikHelpers<PruneDataFormFields>) => {
    const { processName, locationName, processingId, pruneAll, startDate: _startDate, endDate: _endDate } = values;
    let startDate: PruneDataFormFields['startDate'] = _startDate;
    let endDate: PruneDataFormFields['endDate'] = _endDate;

    /** Update form model and perform pre-confirmation validation */
    const handleValidation = () => {
      pruneDataDialogForm.updateForm({ processName, locationName, processingId, startDate, endDate });
      processingService
        .validateForm(validatorPath, ARMFlowForms.PRUNE_DATA, pruneDataDialogForm)
        .then(() => formikHelpers.setSubmitting(false));
    };

    // Immediately validate if not pruning all dates
    if (!pruneAll) {
      handleValidation();
      return;
    }

    // Otherwise, retrieve and assign prunable dates if using "Prune All"
    if (processName && locationName && processingId) {
      const { site, facility } = generateSiteAndFacilityFromLocation(locationName);
      if (!site || !facility) {
        handleValidation();
        return;
      }

      processingService
        .fetchPrunableDates(processName, site, facility, processingId)
        .then(({ newStartDate, newEndDate }) => {
          startDate = newStartDate ?? _startDate;
          endDate = newEndDate ?? _endDate;
        })
        .finally(() => {
          handleValidation();
        });
    }
  };

  // Initialize Formik
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const { values, touched, errors, setFieldValue, setFieldTouched, submitCount, isSubmitting } = formik;

  // Get special handler function for updating our Formik forms
  const handleFormUpdate = useArmflowFormUpdater(setFieldValue, setFieldTouched, submitCount);

  const reset = () => {
    formik.resetForm({ values: PRUNE_DATA_EMPTY_FORM });
    pruneDataDialogForm.reset();
    adiApiService.clearValidatorError(validatorPath);
  };

  const clearDateFields = () => {
    handleFormUpdate([
      { formId: formIds.startDate, value: null },
      { formId: formIds.endDate, value: null },
    ]);
  };

  const handleCancelConfirmation = () => {
    // Clear the date field if prune-all was selected
    if (values.pruneAll) clearDateFields();

    closeConfirmation();
  };

  const handleFormHistorySelect = (formJSON: ArmflowFormJSON) => {
    const converted = PruneDataForm.fromJSON(formJSON);
    formik.setValues({ ...values, ...converted });
  };

  return (
    <div className={clsx(classes.root, className)}>
      {/* Form confirmation dialog */}
      {confirmationOpen && (
        <PruneDataFormConfirm
          open={confirmationOpen}
          onClose={handleCancelConfirmation}
          onCancel={handleCancelConfirmation}
          onSubmit={confirmSubmit}
        />
      )}

      {/* Form dialog */}
      <Dialog
        open={formOpen}
        onClose={(_, reason) => {
          if (reason === 'escapeKeyDown') {
            handleCloseForm();
          }
        }}
      >
        <form className={classes.formStyle} onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogTitle className={classes.dialogTitle}>
            Prune Data
            <Stack direction="row">
              <FormHistory
                disabled={validationLoading}
                form={ARMFlowForms.PRUNE_DATA}
                onSelected={handleFormHistorySelect}
              />
              <ResetButton disabled={isSubmitting} onClick={reset} />
            </Stack>
          </DialogTitle>

          <DialogContent dividers>
            {/* PCM Process Name selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessName
                  id={formIds.processName}
                  value={values.processName}
                  disabled={isSubmitting}
                  onChange={(value, processType) =>
                    handleFormUpdate([
                      { formId: formIds.processName, value },
                      { formId: formIds.locationName, value: null, skipTouch: true }, // Clear location field when process name changes
                      { formId: formIds.processType, value: processType },
                    ])
                  }
                  error={touched.processName && Boolean(errors.processName)}
                  helperText={touched.processName && errors.processName}
                />
              </FormControl>
            </FormGroup>
            {/* Location selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteLocation
                  id={formIds.locationName}
                  disabled={!values.processName || isSubmitting}
                  processName={values.processName}
                  value={values.locationName}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.locationName, value })}
                  error={touched.locationName && Boolean(errors.locationName)}
                  helperText={touched.locationName && errors.locationName}
                />
              </FormControl>
            </FormGroup>

            {/* Processing ID Selection */}
            <FormGroup>
              <FormControl required className={classes.formControl}>
                <AutocompleteProcessingId
                  fetchAllIds
                  processName={values.processName}
                  locationName={values.locationName}
                  id={formIds.processingId}
                  value={values.processingId}
                  disabled={isSubmitting}
                  onChange={(_, value) => handleFormUpdate({ formId: formIds.processingId, value })}
                  error={touched.processingId && Boolean(errors.processingId)}
                  helperText={touched.processingId && errors.processingId}
                />
              </FormControl>
            </FormGroup>

            {/* Prune All Toggle */}
            <FormGroup>
              <FormControlLabel
                label="Prune All"
                disabled={isSubmitting}
                control={
                  <Checkbox
                    id={formIds.pruneAll}
                    checked={values.pruneAll}
                    onChange={(_, pruneAll) => handleFormUpdate({ formId: formIds.pruneAll, value: pruneAll })}
                    name="prune-all"
                  />
                }
              />
            </FormGroup>

            {/* Start/End Date pickers */}
            {!values.pruneAll ? (
              <FormGroup>
                <FormControl className={classes.formControl}>
                  <FormDateRangePicker
                    value={[values.startDate, values.endDate]}
                    startText="Start"
                    endText="End"
                    startHelperText={touched.startDate && errors.startDate}
                    endHelperText={touched.endDate && errors.endDate}
                    disabled={isSubmitting}
                    startError={
                      (touched.startDate && Boolean(errors.startDate)) || !pruneDataDialogForm.validArchiveStartDate
                    }
                    endError={(touched.endDate && Boolean(errors.endDate)) || !pruneDataDialogForm.validArchiveEndDate}
                    onChange={(newValue) => {
                      const [startDate, endDate] = newValue;
                      pruneDataDialogForm.updateDate({ begin: startDate, end: endDate });

                      // check if start/end are different from stored; set skipTouch accordingly
                      const skipStartTouch = startDate?.toISODate() === values.startDate?.toISODate();
                      const skipEndTouch = endDate?.toISODate() === values.endDate?.toISODate();
                      handleFormUpdate([
                        { formId: formIds.startDate, value: startDate, skipTouch: skipStartTouch },
                        { formId: formIds.endDate, value: endDate, skipTouch: skipEndTouch },
                      ]);
                    }}
                  />
                </FormControl>
              </FormGroup>
            ) : null}

            {/* Pre-confirm form validation */}
            <ValidationBox apiPath={validatorPath} />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseForm}>Cancel</Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={isSubmitting || (!formik.isValid && formik.submitCount > 0)}
            >
              Prune Data
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
});
